import React, { useEffect } from 'react'
import { useState } from 'react';
import { Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getMotifConsultation } from '../../../redux/features/Consultation/ConsultationSlice';

import style from './ChoixMotif.module.css'

export default function ChoixMotif({ doctor, formData ,setFormData, setPage }) {



  const dispatch = useDispatch()

  const { motifconsultation } = useSelector((state) => state.consultation)

  const [validation, setValidation] = useState('')

  useEffect(() => {
    dispatch(getMotifConsultation(doctor))
  },  [dispatch]);


  const motifChangeHandler = (e) => {
    setFormData({...formData, motif_consultation : e.target.value })
  }

  const onButtonClick = () => {
    if (formData.motif_consultation === "") {
      setValidation('choisissez un motif de consultation svp')
    }
    else {
      setPage(1)
    }
  }


  return (
    <Fragment>
      <div className={style.container}>
        <div className={style.form_container}>
          <div className={style.text}>
            <h2>Type de consulation</h2>
          </div>
          <div className={style.combobox}>
            <select
            onChange={motifChangeHandler}
            name="motif">
              <option value=""></option>
              {motifconsultation && motifconsultation.map((el, i) => 
                <option
                key={i} value={el.id}>{el.nom}</option>
              )}
            </select>
            <div className={style.validation}>
              <p>
                {validation && validation}
              </p>
            </div>
          </div>
        </div>
        <div className={style.button}>
          <button
          onClick={onButtonClick}
          >Suivant</button>
        </div>
      </div>
    </Fragment>
  )
}
