import { configureStore } from "@reduxjs/toolkit";

import auth from "./features/Auth/AuthSlice";
import doctor from "./features/Doctor/DoctorSlice"
import location from './features/Location/LocationSlice'
import appointments from './features/Appointments/AppointmentsSlice'
import consultation from './features/Consultation/ConsultationSlice'
import profile from './features/Profile/ProfileSlice'
import document from './features/Document/DocumentSlice'
import marketing from './features/Marketing/MarketingSlice'
import analitic from './features/Analitic/AnaliticSlice'


export default configureStore({
  reducer: {
    auth,
    doctor,
    location,
    appointments,
    consultation,
    profile,
    document,
    marketing,
    analitic,
  },
});