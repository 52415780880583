import './App.css';
import { Routes, Route, useLocation } from 'react-router-dom';

import Home from './Pages/Home/Home';
import Signup from './Pages/Signup/Signup';
import Signin from './Pages/Signin/Signin'
import ForgotPassword from './Pages/ForgotPassword/ForgotPassword';
import ChangeForgotPassword from './Pages/ChangeForgotPassword/ChangeForgotPassword';
import Layout from './Components/Layout/Layout';
import { Fragment, useEffect } from 'react';
import DoctorList from './Pages/DoctorList/DoctorList';
import DoctorDetails from './Pages/DoctorDetails/DoctorDetails';
import Account from './Pages/Account/Account';
import Coordonne from './Pages/Coordonne/Coordonne';
import Document from './Pages/Document/Document';
import Appoitment from './Pages/Appoitment/Appoitment';
import ConsultationDetails from './Pages/ConsultationDetails/ConsultationDetails';
import OrdonnanceDetails from './Pages/OrdonnanceDetails/OrdonnanceDetails';
import ChoiceProfile from './Pages/ChoiceProfile/ChoiceProfile';
import ReportDetails from './Pages/ReportDetails/ReportDetails';
import AppoitmentsDetails from './Pages/AppoitmentsDetails/AppoitmentsDetails';
import AddProfile from './Pages/ChoiceProfile/AddProfile/AddProfile';
import AboutUs from './Pages/AboutUs/AboutUs';
import { useDispatch } from 'react-redux';
import { autoLogin } from './redux/features/Auth/AuthSlice';
import ProtectedRoute from './Components/ProtectedRoute/ProtectedRoute';
import DeleteAccount from './Pages/DeleteAccount/DeleteAccount';





function App() {



  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(autoLogin())
  }, [dispatch]);


  return (
    <Fragment>
      <Layout>
        <div className="App">
          <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/aboutus" element={<AboutUs />} />
              <Route path="/delete-account/" element={<DeleteAccount />} />
              <Route path="/signup" element={<Signup />} />
              <Route path="/signin/" element={<Signin />} />


              <Route path="/forgot-password/" element={<ForgotPassword />} />
              <Route path="/change-forgot-password/" element={<ChangeForgotPassword />} />


              <Route path="/:drslug/:doctor/" element={<DoctorDetails />} />
              
              <Route path="/:specslug/:specid/:villeslug/:villeid/:isExepertise/" element={<DoctorList />} />


              
              <Route path="/account" element={<ProtectedRoute><ChoiceProfile /></ProtectedRoute>} />
              <Route path="/addprofile" element={<ProtectedRoute><AddProfile /></ProtectedRoute>} />
              <Route path="/:profilid" element={<ProtectedRoute><Account /></ProtectedRoute>} >
                <Route path="/:profilid/coordonne/" element={<Coordonne />} />
                <Route path="/:profilid/document/" element={<Document />} />
                <Route path="/:profilid/document/ordonnance/:ordonnanceid/" element={<OrdonnanceDetails />} />
                <Route path="/:profilid/document/compterendu/:compterenduid/" element={<ReportDetails />} />
                <Route path="/:profilid/appoitment/" element={<Appoitment />} />
                <Route path="/:profilid/appoitment/app/:appoitmentid/" element={<AppoitmentsDetails />} />
                <Route path="/:profilid/appoitment/:consultationid/" element={<ConsultationDetails />} />
                <Route path="/:profilid/appoitment/:consultationid/ordonnance/:ordonnanceid/" element={<OrdonnanceDetails />} />
                <Route path="/:profilid/appoitment/:consultationid/compterendu/:compterenduid/" element={<ReportDetails />} />
              </Route>
          </Routes>
        </div>
      </Layout>
    </Fragment>
  );
}

export default App;
