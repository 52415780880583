import React, { useState } from 'react'
import { Fragment } from 'react'
import ChoiPatient from './ChoiPatient/ChoiPatient'
import FormAdd from './FormAdd/FormAdd'


import style from  './InfoPatient.module.css'

export default function InfoPatient({ formData, setFormData, setPage }) {


    const [formPage, setFormPage] = useState(0)

    const displayTest = () => {
      if (formPage === 0) {
        return (
          <ChoiPatient setPage={setPage} setFormData={setFormData} formData={formData} setFormPage={setFormPage} />
        )
      } else if (formPage === 1) {
        return (
          <div>
            <FormAdd setFormPage={setFormPage} />
          </div>
        )
      }
    }
  
    

  return (
    <Fragment>
      {displayTest()}
    </Fragment>
  )
}
