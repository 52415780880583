import { Fragment, useEffect, useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import { useDispatch, useSelector } from 'react-redux';

import style from './DeleteAccount.module.css'

import { Button, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, Paper, TextField } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { deleteAccount } from '../../redux/features/Auth/AuthSlice';



export default function DeleteAccount() {


  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [data, setData] = useState({
    email: '',
    password: '',
  })
  const [validation, setValidation] = useState("")

    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleChangeEmail = (email) => {
      setData({...data, email : email.target.value})
    }


    const handleChangePassword = (elem) => {
      setData({...data, password : elem.target.value})
    }

    const handleSubmit = () => {
      setValidation('');
  
      if (data.email === '') {
        setValidation('Veuillez saisir votre adresse e-mail.');
        return;
      }
      
      if (data.password === '') {
        setValidation('Veuillez saisir votre mot de passe.');
        return;
      }
  
  
      dispatch(deleteAccount(data)).unwrap().then((Response) => {
        if (Response.success === "Compte supprimé avec succès") {
          navigate('/')
        } else {
          setValidation('Une erreur est survenue. Veuillez vérifier votre compte et réessayer.')
        }
      })
      .catch((Error) => {
        setValidation('Une erreur est survenue. Veuillez vérifier votre compte et réessayer.')
      })
    }



  return (
    <Fragment>
      <div className={style.page}>
        <div className={style.formConatiner}>
          <h1>Suppression de Compte</h1>
          <TextField
              style={{ marginBottom : "30px" }}
              className={style.input} 
              onChange={email => handleChangeEmail(email)}
                label="Adresse émail"
              >
          </TextField>
          <FormControl 
          style={{ marginBottom : "30px" }}
          className={style.input} variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password">Mot de passe</InputLabel>
              <OutlinedInput
                onChange={(elem) => handleChangePassword(elem)}
                id="outlined-adornment-password"
                type={showPassword ? 'text' : 'password'}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Mot de passe"
              />
            </FormControl>
              {validation &&
                  <div className={style.danger}>
                      <p>{validation}</p>
                  </div>
              }

          <Button
          onClick={handleSubmit}
          style={{ background : "#c70000", marginBottom : "47px" }} className={style.button} variant="contained">
            Supprimer Mon Compte
          </Button>
        </div>

      </div>
    </Fragment>
  )
}
