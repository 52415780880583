import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";





export const getVille = createAsyncThunk('location/getVille',
  async (_, thunkAPI) => {
    const {rejectWithValue} = thunkAPI;
    try {
      const res = await fetch('https://api.doctodz.com/api/location/locationlist/', {
        method: 'GET',
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await res.json();
      return data
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }

);





const LocationSlice = createSlice({
    name: 'location',
    initialState : {
        ville: [],
        isLoading: false,
        error: null,
    },
    reducers: {},
    extraReducers: {
        // loadUser user
        [getVille.pending]: (state, action) => {
            state.isLoading = true;
            state.error = null;
        },
        [getVille.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.ville = action.payload;
        },
        [getVille.rejected]: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;

        },
    },
});


// export const { agreeFunc } = authSlice.actions;

export default LocationSlice.reducer;