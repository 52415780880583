import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";




export const autoLogin = createAsyncThunk('auth/autoLogin',
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    const refreshToken = localStorage.getItem('refresh');
    if (refreshToken) {
      try {
        const res = await fetch('https://api.doctodz.com/api/account/token/refresh/', {
          method: 'POST',
          body: JSON.stringify({ refresh : refreshToken }),
          headers: { 'Content-Type': 'application/json' },
        });
        const data = await res.json();
        if (res.status === 200) {
          localStorage.setItem("token", data.access)
          return data;
        } else if (res.status === 400 || res.status === 401) {
          localStorage.removeItem('token');
          localStorage.removeItem('refresh');
          return rejectWithValue('Token refresh expired');
        }
      } catch (error) {
        return rejectWithValue(error.message);
      }
    } else {
      return rejectWithValue('Token refresh expired');
    }
  }
);



export const loginUser = createAsyncThunk('auth/loginUser',
  async (userData, thunkAPI) => {
    const {rejectWithValue, dispatch} = thunkAPI;
    try {
      const res = await fetch('https://api.doctodz.com/api/account/login/', {
        method: 'POST',
        body: JSON.stringify(userData),
        headers: { 'Content-Type': 'application/json; charset=utf'},
      });
      const data = await res.json();
      if (res.status === 200) {
        localStorage.setItem("token",data.access)
        localStorage.setItem("refresh",data.refresh)
        return data;
      } else if (res.status === 400 || res.status === 401) {
        return rejectWithValue('cant connect');
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);



export const logoutUser = createAsyncThunk('auth/logoutUser',
  async (_, thunkAPI) => {
    const {rejectWithValue} = thunkAPI;
    try {
      localStorage.removeItem("token")
      localStorage.removeItem("refresh")
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
)



export const registerUser = createAsyncThunk('auth/registerUser',
  async (userData, thunkAPI) => {
    const { rejectWithValue, fulfillWithValue } = thunkAPI;
    try {
      const res = await fetch('https://api.doctodz.com/api/account/register/', {
        method: 'POST',
        body: JSON.stringify(userData),
        headers: { 'Content-Type': 'application/json' },
      });
      const data = await res.json();
      if (res.status === 201) {
        localStorage.setItem("token",data.access)
        localStorage.setItem("refresh",data.refresh)
        return fulfillWithValue('succes');
      } else if (res.status === 400 || res.status === 401) {
        return rejectWithValue("Invalid credentials");
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);



export const sendForgotPassword = createAsyncThunk('auth/sendForgotPassword',
    async (email, thunkAPI) => {
      const {rejectWithValue} = thunkAPI;
      try {
        const res = await fetch('https://api.doctodz.com/api/account/patient-forget-password/', {
          method: 'POST',
          body: JSON.stringify(email),
          headers: { 'Content-Type': 'application/json; charset=utf'},
        });
        const data = await res.json();
        if (res.status === 200) {
          return data;
        } else if (res.status === 400 || res.status === 401) {
          return rejectWithValue('email not send');
        }
      } catch (error) {
        return rejectWithValue(error.message);
      }
    }
);


export const changeForgotPassword = createAsyncThunk('auth/changeForgotPassword',
    async (dataa, thunkAPI) => {
      const {rejectWithValue} = thunkAPI;
      try {
        const res = await fetch('https://api.doctodz.com/api/account/change-forgot-password/', {
          method: 'POST',
          body: JSON.stringify(dataa),
          headers: { 'Content-Type': 'application/json; charset=utf'},
        });
        const data = await res.json();
        if (res.status === 200) {
          return data;
        } else if (res.status === 400 || res.status === 401) {
          return rejectWithValue('password not changed');
        }
      } catch (error) {
        return rejectWithValue(error.message);
      }
    }
);


export const deleteAccount = createAsyncThunk('auth/deleteAccount',
    async (dataa, thunkAPI) => {
      const {rejectWithValue} = thunkAPI;
      try {
        const res = await fetch('https://api.doctodz.com/api/account/delete-account/', {
          method: 'POST',
          body: JSON.stringify(dataa),
          headers: { 'Content-Type': 'application/json; charset=utf'},
        });
        const data = await res.json();
        if (res.status === 200) {
          return data;
        } else if (res.status === 400 || res.status === 401) {
          return rejectWithValue('password not changed');
        }
      } catch (error) {
        return rejectWithValue(error.message);
      }
    }
);



const authSlice = createSlice({
    name: 'auth',
    initialState : {
        user: "",
        isLoading: false,
        error: null,
        isAuthenticated: null,
    },
    reducers: {
      resetUser: (state) => {
        state.user = ''
      },
    },
    extraReducers: {
        // loginUser user
        [loginUser.pending]: (state, action) => {
            state.isLoading = true;
            state.error = null;
        },
        [loginUser.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.user = action.payload;
            state.isAuthenticated = true;
        },
        [loginUser.rejected]: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        // auto login
        [autoLogin.pending]: (state, action) => {
            state.isLoading = true;
            state.error = null;
        },
        [autoLogin.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.isAuthenticated = true;
        },
        [autoLogin.rejected]: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        // register user
        [logoutUser.pending]: (state, action) => {
          state.isLoading = true;
          state.error = null;
        },
        [logoutUser.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.isAuthenticated = false;
        },
        [logoutUser.rejected]: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        // register user
        [registerUser.pending]: (state, action) => {
          state.isLoading = true;
          state.error = null;
        },
        [registerUser.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.isAuthenticated = true;
        },
        [registerUser.rejected]: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        // send email ForgotPassword 
        [sendForgotPassword.pending]: (state, action) => {
          state.isLoading = true;
          state.error = null;
        },
        [sendForgotPassword.fulfilled]: (state, action) => {
            state.isLoading = false;
        },
        [sendForgotPassword.rejected]: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },
    },
});


export const { resetUser } = authSlice.actions;

export default authSlice.reducer;