import React, { useEffect } from 'react'
import { Fragment } from 'react'


import image1 from '../../assets/images/doctor-avatar.jpeg'

import { FacebookShareButton, FacebookIcon,
        TwitterShareButton, TwitterIcon,
        TelegramShareButton, TelegramIcon,
        WhatsappIcon, WhatsappShareButton,
        EmailIcon, EmailShareButton,
        LinkedinIcon, LinkedinShareButton,
        } from "react-share";


import InfoIcon from '@mui/icons-material/Info';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import StarRateIcon from '@mui/icons-material/StarRate';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import DateRangeIcon from '@mui/icons-material/DateRange';


import style from './DoctorDetails.module.css'
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { getDoctorDetails, getTarifs } from '../../redux/features/Doctor/DoctorSlice';
import DetailsCalendar from '../../Components/DetailsCalendar/DetailsCalendar'
import { useState } from 'react';
import { Button } from '@mui/material';
import TimeSlotModal from '../../Components/TimeSlotModal/TimeSlotModal';
import { trackProfile } from '../../redux/features/Analitic/AnaliticSlice';




export default function DoctorDetails() {

    const params = useParams()
    const dispatch = useDispatch()

    const { doctor_details, tarifs } = useSelector((state) => state.doctor)
    const { availableappointement } = useSelector((state) => state.appointments)
    const { user } = useSelector((state) => state.auth)


    const userData = {
        doctor : params.doctor,
        user : null,
    }

    if (user) {
        userData['user'] = user.id
    }



    const doctor = params.doctor

    useEffect(() => {
        dispatch(getDoctorDetails(doctor))
        dispatch(getTarifs(doctor))
        dispatch(trackProfile(userData))
    },  [dispatch]);




    const shareUrl = `https://doctodz.com/medecine-generale/oran/${doctor_details.id}/`


    const [detailsModal, setDetailsModal] = useState(false)


  return (
    <Fragment>
        <div className={style.card}>
            <div className={style.img}>
                {doctor_details.photo === null ?
                            <img src={image1} alt="profile" />
                            :
                            <img src={doctor_details.photo} alt="profile" />
                }
            </div>
            <div className={style.text}>
                <h2>{doctor_details.nom}</h2>
                <h3>{doctor_details.specilite && doctor_details.specilite.slug}</h3>
            </div>
        </div>
        <div className={style.body}>
            {availableappointement !== "dont have dr profile" ?
                <div className={style.button}>
                    <Button
                        onClick={() => setDetailsModal(!detailsModal)}
                        style={{ background : "#F87575"}}
                        startIcon={<DateRangeIcon />}
                        variant='contained'>
                        Prenez votre rendez-vous en ligne 
                    </Button>
                </div>
            :
                <div className={style.button}>
                    <Button
                        style={{ background : "#909295"}}
                        variant='contained'>
                        Pas de rendez-vous réservable en-ligne pour ce praticien
                    </Button>
                </div>
            }




            <div className={style.carets}>
                <div className={style.share}>
                    <div className={style.item}>
                        <FacebookShareButton url={shareUrl}>
                            <FacebookIcon
                                size={32} round={true} />
                        </FacebookShareButton>
                        
                    </div>
                    <div className={style.item}>
                        <TwitterShareButton url={shareUrl}>
                            <TwitterIcon
                                size={32} round={true} />
                        </TwitterShareButton>
                    </div>
                    <div className={style.item}>
                        <WhatsappShareButton url={shareUrl}>
                            <WhatsappIcon
                                size={32} round={true} />
                        </WhatsappShareButton>
                    </div>
                    <div className={style.item}>
                        <TelegramShareButton url={shareUrl}>
                            <TelegramIcon
                                size={32} round={true} />
                        </TelegramShareButton>
                    </div>
                    <div className={style.item}>
                        <EmailShareButton url={shareUrl}>
                            <EmailIcon
                                size={32} round={true} />
                        </EmailShareButton>
                    </div>
                    <div className={style.item}>
                        <LinkedinShareButton url={shareUrl}>
                            <LinkedinIcon
                                size={32} round={true} />
                        </LinkedinShareButton>
                    </div>
                </div>
                <div className={style.caret}>
                    <div className={style.caretTitle}>
                        <InfoIcon />
                        <h2>Présentation</h2>
                    </div>
                    <div className={style.caretText}>
                        <p>{doctor_details.specilite && doctor_details.specilite.presentation}</p>
                    
                    <div className={style.langue}>
                        <h3>Langues parlées</h3>
                        <div className={style.lang}>
                            {doctor_details.langue && doctor_details.langue.map((el, i) =>
                                <p key={i}>{el.nom},&nbsp;</p>
                            )}
                        </div>
                    </div>
                    </div>
                </div>

                <div className={style.caret}>
                    <div className={style.caretTitle}>
                        <StarRateIcon />
                        <h2>Expertises et actes</h2>
                    </div>
                    <div className={style.chips}>
                        {doctor_details.expertise && doctor_details.expertise.map((el, i) => 
                            <div key={i} className={style.chip}>
                                {el.nom}
                            </div>
                        )}
                    </div>
                </div>


                <div className={style.caret}>
                    <div className={style.caretTitle}>
                        <LocationOnIcon />
                        <h2>Localisation et adresse</h2>
                    </div>
                    <div className={style.caretText}>
                        <p>{doctor_details.adresse}, {doctor_details.location && doctor_details.location.commune}  {doctor_details.location && doctor_details.location.code} {doctor_details.location && doctor_details.location.ville} {doctor_details.location && doctor_details.location.pays} </p>
                    </div>
                </div>


                <div className={style.caret}>
                    <div className={style.caretTitle}>
                        <LocalPhoneIcon />
                        <h2>Téléphone</h2>
                    </div>
                    <div className={style.caretText}>
                        {doctor_details.phone &&
                            <p>{doctor_details.phone}</p>
                        }
                    </div>
                </div>


                {/* <div className={style.caret}>
                    <div className={style.caretTitle}>
                        <InfoIcon />
                        <h2>Tarifs</h2>
                    </div>
                    <div className={style.caretText}>
                        <div className={style.tarif}>
                            <h3>Langues parlées</h3>
                            <p>Français et Arabe, anglais</p>
                        </div>
                    </div>
                </div> */}



            </div>
            <div className={style.calendarcontainer}>
                <div className={style.calendar}>
                    <DetailsCalendar />
                </div>
            </div>
        </div>
        {detailsModal &&
            <TimeSlotModal />
        }
    </Fragment>
  )
}
