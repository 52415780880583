import React from 'react'
import { Fragment } from 'react'
import { Link, Outlet, useLocation, useNavigate, useParams } from 'react-router-dom'

import style from './Account.module.css'


import ArticleIcon from '@mui/icons-material/Article';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';


export default function Account() {

  const navigate = useNavigate()
  const params = useParams()
  const patient = params.profilid
  const consultation = params.consultationid
  const ordonnance = params.ordonnanceid
  const report = params.compterenduid
  
  const location = useLocation()
  const { pathname } = location;
  const splitLocation = pathname.split("/");




  const getTitle = () => {
    if(splitLocation[2] === "appoitment") {
      return "Historique"
    }
    if(splitLocation[2] === "coordonne") {
      return "Coordonnées"
    }
    if(splitLocation[2] === "document") {
      return "Documents"
    }
    if(splitLocation[2] === "historique") {
      return "Historique"
    }
  }
  
  const handleClickCordo = () => {
    navigate(`/${patient}/coordonne/`)
  }
  const handleClickAppo = () => {
    navigate(`/${patient}/appoitment/`)
  }
  const handleClickDoc = () => {
    navigate(`/${patient}/document/`)
  }
  



  const handleClickReturn = () => {
    if(pathname === `/${patient}/appoitment/${consultation}/`) {
      navigate(`/${patient}/appoitment/`)
    } else if (pathname === `/${patient}/appoitment/${consultation}/ordonnance/${ordonnance}/`) {
      navigate(`/${patient}/appoitment/${consultation}/`)
    } else if (pathname === `/${patient}/document/ordonnance/${ordonnance}/`) {
      navigate(`/${patient}/document/`)
    } else if (pathname === `/${patient}/document/compterendu/${report}/`) {
      navigate(`/${patient}/document/`)
    } else if (pathname === `/${patient}/appoitment/${consultation}/compterendu/${report}/`) {
      navigate(`/${patient}/appoitment/${consultation}/`)
    }
  }

  return (
    <Fragment>
        <div className={style.container}>
          <div className={style.sidebar}>
            
            <div className={style.alllinks}>
              
                <div onClick={handleClickCordo} className={splitLocation[2] === "coordonne" ? style.linkactive : style.link}>
                  <ArticleIcon />
                  <Link to={`/${patient}/coordonne/`} >
                    Coordonnee
                  </Link>
                </div>

                <div onClick={handleClickAppo} className={splitLocation[2] === "appoitment" ? style.linkactive : style.link}>
                  <CalendarMonthIcon />
                  <Link to={`/${patient}/appoitment/`} >
                    Historique
                  </Link>
                </div>


                <div onClick={handleClickDoc} className={splitLocation[2] === "document" ? style.linkactive : style.link}>
                  <PermContactCalendarIcon />
                  <Link to={`/${patient}/document/`} >
                    Documents
                  </Link>
                </div>





            </div>
            {/* <div className={style.appoitment}>
              <button>prendre rendez-vous</button>
            </div> */}
          </div>
          <div className={style.workingspace}>
            <div className={style.topBar}>
              {splitLocation.length >= 5 &&
                <button
                onClick={handleClickReturn}
                >Retour</button>
              }
              <h2>{getTitle()}</h2>
            </div>
            <div className={style.outlet}>
              <Outlet />
            </div>
          </div>

        </div>




    </Fragment>
  )
}
