import React, { Fragment } from 'react'
import DetailsCalendar from '../DetailsCalendar/DetailsCalendar'


import style from './TimeSlotModal.module.css'

export default function TimeSlotModal() {
  return (
    <Fragment>
        <div className={style.overlay}>
          <div className={style.caledra}>
            <DetailsCalendar />
          </div>
        </div>
    </Fragment>
  )
}
