import React, { useRef } from 'react'
import { useState } from 'react'
import { Fragment } from 'react'
import { useDispatch } from 'react-redux'
import { loginUser } from '../../../redux/features/Auth/AuthSlice'


import style from './Indentification.module.css'

export default function Indentification({ setPage }) {



  const dispatch = useDispatch()

  const [validation ,setValidation] = useState('')
  const form = useRef()
  const email = useRef(null);
  const password = useRef(null);


  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      form: form.current.value,
      email: email.current.value,
      password: password.current.value,
    }
    dispatch(loginUser(data)).unwrap()
    .then((Response) => {
      if (Response.email) {
        console.log('connected');
      } else if (Response.detail === "Invalid Credentials or activate account") {
        setValidation('Adresse email ou mot de passe incorrecte')
      }
    })
    .catch((Error) => {
      console.log(Error);
    })
  }



  return (
    <Fragment>
      <div className={style.loginContainer}>
        <div className={style.title}>
          <h2>Identifié vous pour prendre rendez-vous</h2>
        </div>
        <form ref={form} action="">
          <div className={style.inputs}>
            <div className="input">
              <input ref={email} type="text" />
            </div>
            <div className="input">
              <input ref={password} type="password" />
            </div>
            {validation &&
                <div className={style.danger}>
                    <p>{validation}</p>
                </div>
            }
            <div className="input">
              <button
              onClick={handleSubmit}
              >Se connecter</button>
            </div>
          </div>
        </form>
      </div>
    </Fragment>
  )
}
