import React from 'react'
import { Fragment } from 'react'

import { upadateAppoitment } from '../../../redux/features/Appointments/AppointmentsSlice';


import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import PersonIcon from '@mui/icons-material/Person';

import imgDoctor from '../../../assets/images/doctor-avatar.jpeg'


import style from './Finish.module.css'
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom'
import { FormControlLabel, Switch } from '@mui/material';

export default function Finish() {


  const { createdappoitment } = useSelector((state) => state.appointments)
  const dispatch = useDispatch()

  const userData = {
    id : createdappoitment.id,
    alerte : 'True',
  }

  const DeacUserData = {
    id : createdappoitment.id,
    alerte : 'False',
  }


  const handleClickActivate = () => {
    dispatch(upadateAppoitment(userData))
  }

  
  
  const handleClickDeActivate = () => {
    dispatch(upadateAppoitment(DeacUserData))
  }


  const patient = createdappoitment.patient.id

  return (
    <Fragment>
        <div className={style.container}>
          <div className={style.succes}>
            <div className={style.succesCard}>
              <div className={style.title}>
                <CheckCircleIcon />
                <h2>Le rendez -vous est confirmer</h2>
              </div>
              <h3>Nous venons de vous envoyer un email de confirmation de rendez-vous.
              Vous recevez egalement un SMS deux jours avant le rendez-vous.</h3>
            </div>
            <div className={style.button}>
              <Link to={`/${patient}/coordonne/`} >
                <button>
                <PersonIcon />
                <span>
                  Consulter mon copte patient
                </span>
                </button>
              </Link>
            </div>
          </div>
          <div className="details">
            <div className={style.cardAppoitment}>
            <div className={style.header}>
                <div className={style.gggg}>
                    <CalendarMonthIcon />
                    <p>{createdappoitment.date}</p>
                </div>
                <div className={style.gggg}>
                    <AccessAlarmIcon />
                    <p>{createdappoitment.from_time}</p>
                </div>
              </div>

              <div className={style.docDetails}>
                <div className={style.image}>


                  {createdappoitment.created_by.photo === null ?
                      <img src={imgDoctor} alt="profile" />
                      :
                      <img src={createdappoitment.created_by.photo} alt="profile" />
                  }
                </div>
                <div className={style.text}>
                  <h2>Dr {createdappoitment.created_by.nom} {createdappoitment.created_by.prenom} </h2>
                  <h3>{createdappoitment.created_by.specilite.nom}</h3>
                </div>
              </div>
              <div className={style.alerte}>
                <AccessAlarmIcon />
                <div className={style.textAletre}>
                  <div className={style.deactivate}>
                    <h2>Vous souhaitez rendre rendez-vous plutot ?</h2>
                  </div>
                  <h3>Activé une alerte et nous vous préviendrons si une disponibilité plus proche se libère</h3>
                </div>
                {createdappoitment.alerte ?
                  <button
                  onClick={handleClickDeActivate}
                  >
                    DESACTIVER L’ALERTE
                      <ToggleOnIcon />
                  </button>
                :
                  <button
                  onClick={handleClickActivate}
                  >
                    ACTIVER L’ALERTE
                      <ToggleOffIcon />
                  </button>
                }
              </div>
              <div className={style.alire}>
                <WarningAmberIcon />
                <h2>A lire avant otre prochain rendez-vous</h2>
              </div>
            </div>
          </div>
        </div>
    </Fragment>
  )
}
