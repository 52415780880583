import React, { useEffect, useState } from 'react'

import styles from './Coordonne.module.css'


import MailOutlineIcon from '@mui/icons-material/MailOutline';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import { useDispatch, useSelector } from 'react-redux';
import { getProfileDetails, updatePatientDetails } from '../../redux/features/Profile/ProfileSlice';
import { useParams } from 'react-router-dom';


export default function Coordonne() {

    
    const { patientprofile } = useSelector((state) => state.profile)



    const dispatch = useDispatch()
    const params = useParams()
    const patient = params.profilid



  const [error , setError] = useState('');
  const [formData, setFormData] = useState({
        id : "",
        nom: "",
        prenom: "",
        sexe:"",
        // civ: "",
        nom_de_naissance: "",
        date_de_naissance: "",
        pays_de_naissance: "",
        ville_de_naissance: "",
        email: "",
        phone_number:"",
    })


    const HandleSubmit = (e) => {
        e.preventDefault();
        if (formData.nom && formData.prenom && formData.ville_de_naissance && formData.date_de_naissance && formData.phone_number && formData.pays_de_naissance && formData.sexe && formData.nom_de_naissance && formData.email) {
            dispatch(updatePatientDetails(formData)).then(() => {
                setError('Vos modificatios ont bien etais enregitrer')
            })
        } else {
            setError('Veuillez Remplire tout les champs du formulaire') 
        }
    }


    useEffect(() => {
        dispatch(getProfileDetails(patient))
    },  [dispatch]);


    useEffect(() => {
        if (patientprofile.id) {
            setFormData(patientprofile)
        }
    },  [patientprofile]);


return (
    <div className={styles.container}>
        {/* <div className={styles.label}>
            <label htmlFor="civilité">Civilité</label>
        </div>
            <div className={`${styles.input} ${styles.civ}`}>
                <div  className={styles.radio}>
                <input onChange={(e) => setFormData({...formData , civ:e.target.value })} type="radio" id='M' name='civilité' value="Monsieur" /> 
                <label htmlFor="M">M.</label>
                </div>
                <div className={styles.radio}>
                <input onChange={(e) => setFormData({...formData , civ:e.target.value })} type="radio" id='Mme' name='civilité' value="Madame" />
                <label htmlFor="Mme">Mme.</label>
                </div>
            </div> */}
            <div className={styles.label}>
                <label htmlFor="sexe">Sexe</label>
            </div>
            <div className={`${styles.input} ${styles.sexe}`}>
                <div className={styles.radio}>
                <input
                    onChange={(e)=>setFormData({...formData , sexe:e.target.value })}
                    type="radio" id="homme" name="sexe" value="homme" checked={formData.sexe === "homme"} />

                    <label htmlFor="Homme">Homme</label>
                </div>
                <div className={styles.radio}>
                <input
                    onChange={(e)=>setFormData({...formData , sexe:e.target.value })}
                    type="radio" id="femme" name="sexe" value="femme" checked={formData.sexe === "femme"} />
                    <label htmlFor="Femme">Femme</label>
                </div>
            </div>
            <div className={styles.centerContainer}>
            <div className={styles.LeftContainer}>
            <label htmlFor="nom">Nom</label>
            <div className={`${styles.input} ${styles.nom}`}>
                <input onChange={(e)=>setFormData({...formData , nom:e.target.value })} value={formData.nom} type="text" placeholder='Nom'  />
            </div>
            <label htmlFor="nomNais">Nom de naissance</label>
            <div className={`${styles.input} ${styles.nomNais}`}>
                <input onChange={(e)=>setFormData({...formData , nom_de_naissance:e.target.value })} value={formData.nom_de_naissance} type="text" name='nomNais' placeholder='Nom de naissance' />
            </div>
            <label htmlFor="paysNais">Pays de naissance</label>
            <div className={styles.inputs}>
                <select
                    onChange={(e)=>setFormData({...formData , pays_de_naissance : e.target.value })}
                    name="pays">
                    {formData.pays_de_naissance === 'Algérie' ? <option value={formData.pays_de_naissance}>Algérie</option> :
                    formData.pays_de_naissance === 'Autre' ? <option value={formData.pays_de_naissance}>Autre</option> : <option></option> }
                    <option value="Algérie">Algérie</option>
                    <option value="Autre">Autre</option>
                </select>
            </div>

            <label htmlFor="numTel">Telephone portable</label>
            <div className={`${styles.input} ${styles.numTel}`}>
                <LocalPhoneIcon />
                <input style={{paddingLeft : "10px"}} value={formData.phone_number} onChange={(e)=>setFormData({...formData , phone_number:e.target.value })} type="text" name='numTel' placeholder='Téléphone portable' />
            </div>
            </div>
            <div className={styles.rightContainer}>
            <label htmlFor="prenom">Prénom</label>
            <div className={`${styles.input} ${styles.prenom}`}>
                <input value={formData.prenom} onChange={(e)=>setFormData({...formData , prenom:e.target.value })} type="text" placeholder='Prénom' />
            </div>
            <label htmlFor="dateNais">Date de naissance</label>
            <div className={`${styles.input} ${styles.dateNais}`}>
                <input value={formData.date_de_naissance} onChange={(e)=>setFormData({...formData , date_de_naissance:e.target.value })} type="text" name='dateNais' placeholder='Date de naissance' />
            </div>
            <label htmlFor="villeNais">Ville de naissance</label>
            <div className={`${styles.input} ${styles.villeNais}`}>
                <LocationOnOutlinedIcon />
                <input value={formData.ville_de_naissance} onChange={(e)=>setFormData({...formData , ville_de_naissance:e.target.value })} style={{paddingLeft:"8px"}} type="text" name='villeNais' placeholder='Ville de naissance' />
            </div>
            <label htmlFor="adrEmail">Adresse email</label>
            <div className={`${styles.input} ${styles.adrEmail}`}>
                <MailOutlineIcon fontSize="medium" />
                <input value={formData.email} onChange={(e)=>setFormData({...formData , email:e.target.value })} style={{paddingLeft:"10px"}} type="text" name='adrEmail' placeholder='Adresse email'  />
            </div>
            </div>
            </div>
            {error ? (
                <div style={error === "Vos modificatios ont bien etais enregitrer" && {color : "#4BB543"}}  className={styles.error}>
                    <p>{error} *</p>
                </div>
            ) : null }
            <div className={styles.button}>
                <button className={styles.Annuler} >Annuler</button>
                <button className={styles.submit} onClick={HandleSubmit} >Enregistrer</button>
            </div>        

    </div>
  )
}
