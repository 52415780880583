import React, { useEffect } from 'react'
import { Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { getOrdonnanceDetails } from '../../redux/features/Document/DocumentSlice'


import moment from 'moment'

import style from './OrdonnanceDetails.module.css'

export default function OrdonnanceDetails() {


    const { prescriptiondetails } = useSelector((state) => state.document)


    const dispatch = useDispatch()
    const params = useParams()
    const patient = params.profilid
    const document = params.ordonnanceid

    const userData = {
        "patient" : patient,
        "id" : document
    }

    useEffect(() => {
        dispatch(getOrdonnanceDetails(userData))
    }, [dispatch]);




return (
    <Fragment>
        <div className={style.container}>
            <div className={style.body}>
                <div className={style.bodyheader}>
                    <div className={style.doctor}>
                        <h2>Dr {prescriptiondetails.id && prescriptiondetails.created_by.nom} {prescriptiondetails.id && prescriptiondetails.created_by.prenom}</h2>
                        <h3>{prescriptiondetails.id && prescriptiondetails.created_by.specilite.nom}</h3>

                        <h3>{prescriptiondetails.id && prescriptiondetails.created_by.adresse}, &nbsp;
                            {prescriptiondetails.id && prescriptiondetails.created_by.location.code} &nbsp;
                            {prescriptiondetails.id && prescriptiondetails.created_by.location.ville}
                        </h3>
                    </div>
                    <div className={style.patient}>
                        <h3>Le {prescriptiondetails.id && moment(prescriptiondetails.date).format("D MMMM y")}</h3>
                        <h2>M. {prescriptiondetails.id && prescriptiondetails.patient.nom}&nbsp;
                        {prescriptiondetails.id && prescriptiondetails.patient.prenom}
                        </h2>
                        <h3>Né le {prescriptiondetails.id && moment(prescriptiondetails.patient.date_de_naissance).format("DD-MM-YYYY")}</h3>

                    </div>
                </div>
                <div className={style.label}>
                        <h3>ordonnance</h3>
                </div>
                {prescriptiondetails.id && prescriptiondetails.traitement.map((el, i) => 
                    <div key={i} className={style.medicament}>
                        <div className={style.item}>
                            <h2>{el.medicament.marque} {el.medicament.dosage} {el.medicament.forme}</h2>
                            <span>{el.posologie.usage}</span>
                        </div>
                    </div>
                )}
            </div>
        </div>
    </Fragment>
)
}
