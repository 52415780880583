import React, { useEffect } from 'react'
import { useState } from 'react'
import { Fragment } from 'react'

import AvailabilityCalendar from '../../AvailabilityCalendar/AvailabilityCalendar'
import DetailsCalendar from '../../DetailsCalendar/DetailsCalendar'


import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import style from './Verification.module.css'
import { useDispatch, useSelector } from 'react-redux'




export default function Verification({ object, doctor, setPage, formData }) {



  const { motifconsultation } = useSelector((state) => state.consultation)

  const fond = motifconsultation.find(elem => elem.id == formData.motif_consultation)



  return (
    <Fragment>
      <div className={style.container} >

        <div className={style.card}>
          <div className={style.title}>
            <h2>A lire avant de prendre un rendez-vous</h2>
          </div>
          <div className={style.motif}>
            <h3>Motif de consultation : {fond.nom}</h3>
            <div className={style.object}>
              <p>A {object.start} Le {object.day}</p>
            </div>
          </div>
              <div className={style.lireavant}>
                <p>{fond.message}</p>
                <CheckCircleIcon />
              </div>

          <div className={style.accepte}>
            <button
            onClick={() => setPage(3)}
            >J'accepte</button>
          </div>
        </div>
      </div>
    </Fragment>
  )
}
