import { Fragment, useEffect, useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import { useDispatch, useSelector } from 'react-redux';

import style from './ChangeForgotPassword.module.css'

import { Button, CircularProgress, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, Paper, TextField } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { changeForgotPassword } from '../../redux/features/Auth/AuthSlice';


export default function ChangeForgotPassword() {

  const [validation, setValidation] = useState("")
  const dispatch = useDispatch()
  const navigate = useNavigate()


  const { isLoading } = useSelector((state) => state.auth);


  const [code, setCode] = useState("");

  const [data, setData] = useState({
      code : "",
      new_password1 : "",
  })


  const handleChangePassword = (elem) => {
      setData({...data, new_password1 : elem.target.value})
    }

  
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  
  
  
  const handleSubmit = () => {
      dispatch(changeForgotPassword(data)).then((value) => {
          if (value.payload.message === "Password succefully changed") {
              navigate('/')
          }
      })
  }
  
  const handleChangeCode = (event) => {
      const { value } = event.target;
      const numericValue = value.replace(/\D/g, ""); // Retirer tous les caractères non numériques
      const truncatedValue = numericValue.slice(0, 6); // Limiter la saisie à 6 chiffres
      setData({...data, code : event.target.value})
      setCode(truncatedValue);
    };

  return (
    <Fragment>
      <div className={style.page}>
        <div className={style.formConatiner}>
          <h1>Veuillez saisir le code que vous avez reçu</h1>

          
          <TextField
                className={style.input} 
                style={{ marginBottom : "30px" }}
                type="text"
                value={code}
                onChange={(event) => handleChangeCode(event)}
                label="Veuillez saisir votre code"
          />
          
          <FormControl 
              style={{ marginBottom : "30px" }}
              className={style.input} variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password">Entré votre nouveau mot de passe</InputLabel>
              <OutlinedInput
                onChange={(elem) => handleChangePassword(elem)}
                id="outlined-adornment-password"
                type={showPassword ? 'text' : 'password'}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Entré votre nouveau mot de passe"
              />  
          </FormControl>

              {validation &&
                  <div className={style.danger}>
                      <p>{validation}</p>
                  </div>
              }

          {isLoading ? 
            <CircularProgress />
          :
            <Button
            onClick={handleSubmit}
            style={{ background : "#F87575", marginBottom : "47px" }} className={style.button} variant="contained">
              Soumettre
            </Button>
          }
        </div>

      </div>
    </Fragment>
  )
}
