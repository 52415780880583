import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";



export const trackProfile = createAsyncThunk('analitic/trackProfile',
  async (userData, thunkAPI) => {
    const {rejectWithValue} = thunkAPI;
    try {
        const res = await fetch(`https://api.doctodz.com/api/analitics/trackprofile/`, {
          method: 'POST',
          body: JSON.stringify(userData),
          headers: {
            'Content-Type': 'application/json; charset=utf',
            "Accept": "application/json",
          },
        });
        const data = await res.json();
        return data
      } catch (error) {
          return rejectWithValue(error.message);
      }
    }
);


export const trackPages = createAsyncThunk('analitic/trackPages',
  async (userData, thunkAPI) => {
    const {rejectWithValue} = thunkAPI;
    try {
        const res = await fetch(`https://api.doctodz.com/api/analitics/trackpages/`, {
          method: 'POST',
          body: JSON.stringify(userData),
          headers: {
            'Content-Type': 'application/json; charset=utf',
            "Accept": "application/json",
          },
        });
        const data = await res.json();
        return data
      } catch (error) {
          return rejectWithValue(error.message);
      }
    }
);



const AnaliticSlice = createSlice({
    name: 'analitic',
    initialState : {
        profiletrack: null,
        pagestrack: null,
        isLoading: false,
        error: null,
    },
    reducers: {},
    extraReducers: {
        // get tarifs
        [trackProfile.pending]: (state, action) => {
            state.isLoading = true;
            state.error = null;
        },
        [trackProfile.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.profiletrack = action.payload;
        },
        [trackProfile.rejected]: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        // track pages
        [trackPages.pending]: (state, action) => {
            state.isLoading = true;
            state.error = null;
        },
        [trackPages.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.pagestrack = action.payload;
        },
        [trackPages.rejected]: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },
    },
});


// export const { agreeFunc } = authSlice.actions;

export default AnaliticSlice.reducer;