import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";







export const createProfile = createAsyncThunk('profile/createProfile',
  async (userData, thunkAPI) => {
    const {rejectWithValue} = thunkAPI;
    try {
        const res = await fetch("https://api.doctodz.com/api/patient/createinappoit/", {
          method: 'POST',
          body: JSON.stringify(userData),
          headers: {
            'Content-Type': 'application/json; charset=utf',
            "Authorization": `token ${localStorage.getItem('token')}`,
            "Accept": "application/json",
          },
        });
        const data = await res.json();
        return data
      } catch (error) {
          return rejectWithValue(error.message);
      }
    }
);



export const getPatientList = createAsyncThunk('profile/getPatientList',
  async (_, thunkAPI) => {
    const {rejectWithValue} = thunkAPI;
    try {
      const res = await fetch('https://api.doctodz.com/api/patient/listinappoit/', {
        method: 'GET',
        headers: {
          "Content-Type": "application/json",
          "Authorization": `token ${localStorage.getItem('token')}`,
          "Accept": "application/json",
        },
      });
      const data = await res.json();
      return data
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
)



export const getProfileDetails = createAsyncThunk('profile/getProfileDetails',
  async (patient, thunkAPI) => {
    const {rejectWithValue} = thunkAPI;
    try {
      const res = await fetch(`https://api.doctodz.com/api/patient/detailsinpatientview/${patient}/`, {
        method: 'GET',
        headers: {
          "Content-Type": "application/json",
          "Authorization": `token ${localStorage.getItem('token')}`,
          "Accept": "application/json",
        },
      });
      const data = await res.json();
      return data
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
)


export const updatePatientDetails = createAsyncThunk('profile/updatePatientDetails',
  async (userData, thunkAPI) => {
    const {rejectWithValue} = thunkAPI;
    const { id } = userData;
    try {
      const res = await fetch(`https://api.doctodz.com/api/patient/${id}/update/`, {
        method: 'PUT',
        body: JSON.stringify(userData),
        headers: {
          "Content-Type": "application/json",
          "Authorization": `token ${localStorage.getItem('token')}`,
          "Accept": "application/json",
        },
      });
      const data = await res.json();
      return data
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
)


export const getDoctorListforPatient = createAsyncThunk('profile/getDoctorListforPatient',
  async (patient, thunkAPI) => {
    const {rejectWithValue} = thunkAPI;
    try {
      const res = await fetch(`https://api.doctodz.com/api/patient/doctorofthispatient/${patient}/`, {
        method: 'GET',
        headers: {
          "Content-Type": "application/json",
          "Authorization": `token ${localStorage.getItem('token')}`,
          "Accept": "application/json",
        },
      });
      const data = await res.json();
      return data
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
)






const ProfileSlice = createSlice({
    name: 'profile',
    initialState : {
        patientprofile: {},
        profilelist: [],
        doctorlist: [],
        isLoading: false,
        error: null,
    },
    reducers: {},
    extraReducers: {
        // createProfile
        [createProfile.pending]: (state, action) => {
            state.isLoading = true;
            state.error = null;
        },
        [createProfile.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.patientprofile = action.payload;
        },
        [createProfile.rejected]: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        // get profile list 
        [getPatientList.pending]: (state, action) => {
            state.isLoading = true;
            state.error = null;
        },
        [getPatientList.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.profilelist = action.payload;
        },
        [getPatientList.rejected]: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        [getProfileDetails.pending]: (state, action) => {
            state.isLoading = true;
            state.error = null;
        },
        [getProfileDetails.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.patientprofile = action.payload;
        },
        [getProfileDetails.rejected]: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        // get doctor list to share with 
        [getDoctorListforPatient.pending]: (state, action) => {
            state.isLoading = true;
            state.error = null;
        },
        [getDoctorListforPatient.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.doctorlist = action.payload;
        },
        [getDoctorListforPatient.rejected]: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },
    },
});


// export const { agreeFunc } = authSlice.actions;

export default ProfileSlice.reducer;


