import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";





export const getMotifConsultation = createAsyncThunk('consultation/getMotifConsultation',
  async (doctor, thunkAPI) => {
    const {rejectWithValue} = thunkAPI;
    try {
      const res = await fetch(`https://api.doctodz.com/api/consulatation/listmotifconsultation/${doctor}/`, {
        method: 'GET',
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await res.json();
      return data
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }

);





const ConsultationSlice = createSlice({
    name: 'consultation',
    initialState : {
        motifconsultation: [],
        isLoading: false,
        error: null,
    },
    reducers: {},
    extraReducers: {
        // loadUser user
        [getMotifConsultation.pending]: (state, action) => {
            state.isLoading = true;
            state.error = null;
        },
        [getMotifConsultation.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.motifconsultation = action.payload;
        },
        [getMotifConsultation.rejected]: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;

        },
    },
});


// export const { agreeFunc } = authSlice.actions;

export default ConsultationSlice.reducer;