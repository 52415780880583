import React, { useEffect } from 'react'
import { Fragment } from 'react'

import infermiere from '../../assets/images/infermiere.png'
import usephone from '../../assets/images/use-phone.png'
import startupLogo from '../../assets/images/startup-logo.png'
import suggestdoc from '../../assets/images/doc.png'
import joindoc from '../../assets/images/doc-3.png'

import style from './Home.module.css'

import CloseIcon from '@mui/icons-material/Close';
import SearchBar from '../../Components/SearchBar/SearchBar';
import { useNavigate } from 'react-router-dom'
import { Button, Modal, Paper, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography } from '@mui/material'
import { useState } from 'react'
import { Box } from '@mui/system'

import { useDispatch } from 'react-redux'
import { SuggesteDoctor } from '../../redux/features/Marketing/MarketingSlice'


import { FacebookShareButton, FacebookIcon,
  TwitterShareButton, TwitterIcon,
  TelegramShareButton, TelegramIcon,
  WhatsappIcon, WhatsappShareButton,
  EmailIcon, EmailShareButton,
  LinkedinIcon, LinkedinShareButton,
  } from "react-share";



import ReactGA from 'react-ga4';

export default function Home() {


  const navigate = useNavigate()
  const dispatch = useDispatch()



  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname});
  }, []);

  const [data, setData] = useState({ 
      specslug : "medecine-generale",
      specid : 1,
      villeslug : "oran-oran-algerie",
      villeid : 1,
      isExepertise : 0,
  })


  const [doctor, setDoctor] = useState({
    name : "",
    speciality : "",
    phone : null,
  })


  const [open, setOpen] = useState(false)

  const handleClose = () => {
    setOpen(!open)
  }


  const handleClickSubmit = () => {
    dispatch(SuggesteDoctor(doctor)).then(() => {
      setOpen(!open)
    })
  }



  const handleChangeName = (Name) => {
    setDoctor({...doctor, name : Name.target.value})
  }


  const handleChangeSpeciality = (speciality) => {
    setDoctor({...doctor, speciality : speciality.target.value})
  }


  const handleChangePhone = (phone) => {
    setDoctor({...doctor, phone : phone.target.value})
  }



  const handleClickSuggest = () => {
    setOpen(!open)
    ReactGA.event({
      category: 'Button',
      action: 'Click',
      label: 'Suggérer mon médecin'
    });
  }


  const handleClickJoin = () => {
    ReactGA.event({
      category: 'Button',
      action: 'Click',
      label: 'Rejoignez-nous dès maintenant !'
    });
  }


  const handleClickShare = () => {
    ReactGA.event({
      category: 'Button',
      action: 'Click',
      label: "Soutenez l'innovation"
    });
  }



  const shareUrl = `https://doctodz.com/`
  const hashtags = "#doctodz #santé"

  return (
    <Fragment>
      <Paper elevation={0} >
        <div className={style.search_container}>
          <div className="title_search">
            <h1>Prenez rendez-vous avec un professionnels de santé</h1>
            <SearchBar data={data} setData={setData} />
          </div>

          <div className={style.cards}>
            <Paper className={style.card}>
              <div className="img">
                  <img src={startupLogo} alt="" />
              </div>
              <div className={style.text}>
              <h3>Doctodz est une startup labellisée par le ministère des Startups et de la Connaissance en Algérie.</h3>
              <a href="#share-section">
                <Button
                onClick={handleClickShare}
                disabled={false}
                    >Soutenez l'innovation
                </Button>
              </a>
              </div>
            </Paper>


            <Paper className={style.card}>
              <div className="img">
                  <img src={suggestdoc} alt="" />
              </div>
              <div className={style.text}>
              <h3>Votre médecin n’utilise pas encore Doctodz ?</h3>
              <Button
                  onClick={handleClickSuggest}
                  >Suggérer mon médecin</Button>
              </div>
            </Paper>


            <Paper className={style.card}>
              <div className="img">
                  <img src={joindoc} alt="" />
              </div>
              <div className={style.text}>
              <h3>Vous êtes professionnel de santé et vous n'utilisez toujours pas Doctodz ?</h3>
              <a href="https://info.doctodz.com" target="_blank" rel="noopener noreferrer" >
                <Button
                onClick={handleClickJoin}
                    >Rejoignez-nous dès maintenant !
                </Button>
              </a>
              </div>
            </Paper>
          </div>
        </div>
      </Paper>


      <Paper elevation={0}>
        <div id="share-section" className={style.ShareSection}>
          <h2>Partagez Doctodz et soutenez l'innovation en Algérie !</h2>
          <div className={style.responsive}>
            <p>Doctodz est une startup labellisée par le ministère des Startups et de la Connaissance en Algérie. Nous révolutionnons la prise de rendez-vous médicaux en ligne. En soutenant notre plateforme, vous participez à l'essor de l'innovation dans le secteur de la santé en Algérie. Ensemble, faisons en sorte que chaque personne puisse accéder facilement aux soins médicaux. Partagez Doctodz avec vos proches et contacts sur les réseaux sociaux et aidez-nous à améliorer l'accès aux services médicaux pour tous les Algériens. Cliquez sur le bouton ci-dessous pour partager Doctodz et rejoindre notre mission d'innovation et de santé pour tous !
            </p>
            <div className="img">
              <img src={startupLogo} alt="" />
            </div>
          </div>
            <div className={style.share}>
                <div className={style.item}>
                <FacebookShareButton
                  hashtag={hashtags}
                  url={shareUrl}
                >
                  <FacebookIcon size={32} round={true} />
                </FacebookShareButton>
                </div>
                <div className={style.item}>
                    <TwitterShareButton url={shareUrl}>
                        <TwitterIcon
                            size={32} round={true} />
                    </TwitterShareButton>
                </div>
                <div className={style.item}>
                    <WhatsappShareButton url={shareUrl}>
                        <WhatsappIcon
                            size={32} round={true} />
                    </WhatsappShareButton>
                </div>
                <div className={style.item}>
                    <TelegramShareButton url={shareUrl}>
                        <TelegramIcon
                            size={32} round={true} />
                    </TelegramShareButton>
                </div>
                <div className={style.item}>
                    <EmailShareButton url={shareUrl}>
                        <EmailIcon
                            size={32} round={true} />
                    </EmailShareButton>
                </div>
                <div className={style.item}>
                    <LinkedinShareButton url={shareUrl}>
                        <LinkedinIcon
                            size={32} round={true} />
                    </LinkedinShareButton>
                </div>
          </div>
        </div>
      </Paper>




      <Paper elevation={0}>
        <div className={style.acces_container}>
          <div className="title">
            <h2>Nous croyons que tout le monde devrait avoir un accès facile à d'excellents soins de santé</h2>
          </div>
          <div className={style.image_liste}>
            <div className="image">
              <img src={usephone} alt="accessibilité" />
            </div>
            <div className="listes">
              <ul>
                <li>Recherchez facilement les disponibilités de tous les professionnels de santé</li>
                <li>Consultez vos résultats d'examens et rapports médicaux en ligne, où que vous soyez</li>
                <li>Accédez à une base de connaissances médicales complète pour obtenir des informations fiables sur votre santé</li>
                <li>Gérez vos rendez-vous et documents de santé ainsi que ceux de vos proches sur une même application</li>
              </ul>
            </div>
          </div>
        </div>
      </Paper>

          {/* <Paper elevation={0}>
            <div className={style.dont_container}>
              <div className="text">
                <h2>Votre médecin n’utilise pas encore Doctodz ?</h2>
                <h3>Renseignez ses coordonnées. Notre équipe s’occupe du reste !</h3>
              </div>
                  <Button
                  onClick={handleClose}
                  style={{ background : "#00A6FB"}}
                  >Suggérer mon médecin</Button>
            </div>
          </Paper> */}



        {/* <div id="contact" className={style.contact}>
          <h2>Contactez-nous</h2>
          <h3>Pour toute demande, veuillez nous contacter au : 0774 512 158</h3>
        </div> */}



      



      {/* <Paper elevation={0}>
        <div className={style.cta_container}>
          <div className="content">
            <div className={style.title}>
              <h2>Vous êtes professionnel de santé ?</h2>
              <h3>Équipez-vous du logiciel DoctoDz pour gagner en confort de travail.</h3>
            </div>
            <div className="features">
              <ul>
                <li>Economisez du temps médical grâce à la prise de rendez-vous en ligne et un logiciel de nouvelle generation.</li>
                <li>Développez l'activité de votre cabinet selon vos besoins grâce à une meilleure visibilité en ligne.</li>
                <li>Optimisez votre gestion administrative.</li>
                <li>Simplifiez votre quotidien du travail en réduisant les appels téléphoniques à votre cabinet.</li>
                <li>Améliorez l'accès aux soins avec la teléconsultation.</li>
                <li>Permettez d'ameliorer la satisfaction de vos patients,et les fidéliser</li>
              </ul>
            </div>
            <button>Commander Doctodz</button>
          </div>
          <div className="image">
            <img src={suggestdoc} alt="bureau" />
          </div>
        </div>
      </Paper> */}



      <Dialog open={open} onClose={handleClose}>
        <div className={style.modal}>
            <div className={style.titles}>
              <h1>Votre médecin n’utilise pas encore Doctodz ?</h1>
              <h2>Renseignez ses coordonnées. Notre équipe s’occupe du reste !</h2>
            </div>
            <div className={style.form}>
              <TextField
              type='text'
                  style={{ marginBottom : "30px" }}
                  className={style.input}
                  onChange={Name => handleChangeName(Name)}
                    label="Non du medcein"
                  >
              </TextField>
              <TextField
              type='text'
                  style={{ marginBottom : "30px" }}
                  className={style.input}
                  onChange={speciality => handleChangeSpeciality(speciality)}
                    label="Spécialité"
                  >
              </TextField>
              <TextField
                  name='phone'
                  style={{ marginBottom : "30px" }}
                  className={style.input}
                  onChange={phone => handleChangePhone(phone)}
                    label="Numero de téléphone"
                  >
              </TextField>
            </div>
            <div className={style.actions}>
              <DialogActions>
                <Button onClick={handleClose} color="primary">
                  Fermé
                </Button>
                <Button 
                variant='contained'
                style={{ background : "#00A6FB" }}
                onClick={handleClickSubmit} >
                  Transmettre à l'équipe
                </Button>
              </DialogActions>
            </div>
        </div>
      </Dialog>



    </Fragment>
  )
}





