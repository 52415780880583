import React, { useEffect, useState } from 'react'
import { Fragment } from 'react'

import SearchIcon from '@mui/icons-material/Search';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import LocationOnIcon from '@mui/icons-material/LocationOn';


import style from './SearchBar.module.css'
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getVille } from '../../redux/features/Location/LocationSlice';
import { getDoctorByExpertise, getDoctorList, getExpertise, getFiltredDoctor, getSpeciality } from '../../redux/features/Doctor/DoctorSlice';

import moment from 'moment';
import { Autocomplete, Box, Button, TextField } from '@mui/material';

export default function SearchBar({data, setData}) {

  const dispatch = useDispatch()
  const navigate = useNavigate()

  
  const { ville } = useSelector((state) => state.location)
  const { speciality, doctorlist, expertise } = useSelector((state) => state.doctor)


  useEffect(() => {
    dispatch(getVille())
    dispatch(getSpeciality())
    dispatch(getDoctorList())
    dispatch(getExpertise())
  },  [dispatch]);

  const onVilleSuggestHandler = (newValue) => {
    setData({...data, villeid : newValue.id, villeslug : newValue.slug })
  }






  const handleSearchClick = () => {
    if (data.isExepertise !== 0) {
      dispatch(getDoctorByExpertise(data))
      navigate(`/${data.specslug}/${data.specid}/${data.villeslug}/${data.villeid}/${data.isExepertise}/`)
    } else if (data.isExepertise === 0) {
      dispatch(getFiltredDoctor(data))
      navigate(`/${data.specslug}/${data.specid}/${data.villeslug}/${data.villeid}/${data.isExepertise}/`)
    }
  }

  const onVilleSpecialiteChange = (d) => {
    if (d.isExepertise) {
      setData({...data, isExepertise : 1, specid : d.id, specslug : d.slug })
    } else {
      setData({...data, isExepertise : 0, specid : d.id, specslug : d.slug })
    }
  }
  
  
  
  
  
  
  
  const onNameChangeHandler = (newValue) => {
    navigate(`/${newValue.slug}/${newValue.id}/`)
  }
  
  const mergedData = [
    ...speciality.map(item => ({ ...item, index: `s${item.id}` })),
    ...expertise.map(item => ({ ...item, index: `e${item.id}` })),
  ];
  




  return (
    <Fragment>

      <div className={style.container}>
        <div className={style.searchBar}>
          <div className={style.inputs}>


          <div className={style.input}>
                  <Autocomplete
                    freeSolo
                    style={{ marginBottom: "15px", background: "#fff" }}
                    options={doctorlist}
                    getOptionLabel={option => option.nom}
                    autoHighlight
                    sx={{ width: 300 }}
                    onChange={(event, newValue) => onNameChangeHandler(newValue)}
                    renderInput={params => (
                      <TextField
                        placeholder="Nom du médecin"
                        {...params}
                        value="test"
                        variant="outlined"
                        fullWidth
                      />
                    )}
                    renderOption={(props, option) => (
                      <li {...props} key={option.id}>
                        {option.nom}
                      </li>
                    )}
                  />
              </div>

              <div className={style.input}>
                  <Autocomplete
                    freeSolo
                    style={{ marginBottom: "15px", background: "#fff" }}
                    // options={[...speciality, ...expertise]}
                    options={mergedData}
                    getOptionLabel={option => option.nom}
                    autoHighlight
                    sx={{ width: 300 }}
                    onChange={(event, newValue) => onVilleSpecialiteChange(newValue)}
                    renderInput={params => (
                      <TextField
                        placeholder="Pathologie, specialité"
                        {...params}
                        value="test"
                        variant="outlined"
                        fullWidth
                      />
                    )}
                    renderOption={(props, option) => (
                      <li {...props} key={option.index}>
                        {option.nom}
                      </li>
                    )}
                  />
              </div>



              <div className={style.input}>
                <Autocomplete
                className={style.ville}
                style={{ marginBottom : "15px", background : "#fff" }}
                    freeSolo
                    filterSelectedOptions
                    options={ville}
                    getOptionLabel={option => option.commune}
                    autoHighlight
                    sx={{ width: 300 }}
                    onChange={(event, newValue) => onVilleSuggestHandler(newValue)}
                    renderOption={(props, option) => (
                      <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>

                        {option.commune} - {option.ville}
                      </Box>
                    )}
                    renderInput={params => (
                      <TextField
                      placeholder="Ou (ville, commune) ?"
                      {...params} value="test" variant="outlined" fullWidth />
                    )}
                />
              </div>



              <div className={style.serch_button}>
                <Button
                onClick={handleSearchClick}
                className={style.button}
                  variant="contained"
                  style={{ background : "#F87575", borderRadius : 2}}
                  endIcon={<SearchIcon />}>
                  Recherche
                </Button>
              </div>
          </div>
        </div>
      </div>




      {/* <div className={style.container}>
          <div className={style.search}>
            <div className={style.search_input}>
              <div className={style.name_search}>
                <SearchIcon />
                <input
                onChange={e => onSpecialChangeHandler(e.target.value)}
                value={textSpec}
                  placeholder="Nom ou specialite de votre médcin"
                  className={style.first_input} type="text" />
                  <div className={style.allsuggestion}>
                        {suggestionSpec && suggestionSpec.map((els, i) => 
                              <div
                              onClick={() => onSpecSuggest(els.slug)}
                              className={style.suggestion}
                              key={i}>
                                  {els.slug}
                              </div>
                        )}
                        {suggestionDoctor && suggestionDoctor.map((doc, i) => 
                              <div
                              onClick={() => onDoctorSuggest(doc)}
                              className={style.suggestion}
                              key={i}>

                                <p>
                                  {doc.nom}
                                </p> &nbsp;
                                <p>
                                  {doc.prenom}
                                </p>

                              </div>
                        )}
                  </div>
              </div>



              <div className="location">
                <LocationOnIcon />
                <input
                onChange={e => onVilleChangeHandler(e.target.value)}
                value={text}
                placeholder="Ville"
                type="text" />
                <div className={style.allsuggestion}>
                      {suggestion && suggestion.map((el, i) => 
                            <div
                              onClick={() => onVilleSuggestHandler(el.ville)}
                              className={style.suggestion}
                                key={i}>
                                {el.ville}
                            </div>
                      )}
                </div>
              </div>
            </div>
            <div
            onClick={handleSearchClick}
            className={style.serch_button}>
              <span>rechercher</span>
              <ChevronRightIcon />
            </div>
          </div>
      </div> */}
    </Fragment>
  )
}
