import React, { useEffect, useState } from 'react'
import 'moment/locale/fr'
import moment from "moment"
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { useRef } from 'react';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAvailableAppointement } from '../../redux/features/Appointments/AppointmentsSlice'

import style from './DetailsCalendar.module.css'
import { useParams } from 'react-router-dom';
import Modal from '../Modal/Modal';








export default function DetailsCalendar() {



  const params = useParams()
  const dispatch = useDispatch()
  const { availableappointement } = useSelector((state) => state.appointments)


  const doctor = params.doctor
  const [modal, setModal] = useState(false)
  const [object, setObject] = useState({})


  useEffect(() => {
    dispatch(getAvailableAppointement(doctor))
  },  [dispatch]);


    moment.locale('fr')

    const SliderRef = useRef(null)

    const settings = {
        slidesToShow: 6,
        slidesToScroll: 6,
        infinite : false,
        arrows : false,
    };

    const data = availableappointement
    const reorganize = (data)=> {
      if (data.length > 0) {
        if (data !== "dont have dr profile") {
          let cont = 0;
          let index = 1;
          let array = [{
            day : data[cont].day,
            rdv : [data[cont].start],
          }];
  
        while (index < data.length) {
            if (array[cont].day === data[index].day) {
                array[cont].rdv.push(data[index].start)
            } else {
                array = [...array , {
                    day: data[index].day,
                    rdv : [data[index].start]
                }]
                cont++
            }
            index++
        }
        return array
        }
      }}

    const newData = reorganize(data)

    const handleClickAppoitment = (it, jour) => {
      const obj = data.find(object => object.day === jour && object.start === it)
      setModal(!modal)
      setObject(obj)
    }



    const displayAppoitments = () => {
      if (newData) {
        return (
          <div className={style.container}>
            <div className={style.head}>
              <h1>Prenez votre rendez-vous en ligne </h1>
              <h2>Reseignez les informations suivantes</h2>
            </div>
            <div className={style.calendar}>
                <div className={style.arrow} ><ArrowBackIosIcon onClick={()=>SliderRef.current.slickPrev()} />
                </div>
                  <div className={style.slider_container}>
                      <Slider
                        ref={SliderRef}
                        {...settings}
                        >
                        {newData && newData.map((item, i)=> (
                            <div className={style.table} key={i}>
                                <div className={style.rowcalendar}><p>{moment(item.day).format("dddd")}</p>
                                  <p>{moment(item.day).format("Do MMMM")}</p>
                                </div>
                                <hr></hr>
                                <div className={style.columnCalendar}>
                                  {item.rdv && item.rdv.map((it, i) => {
                                      return (
                                          <div
                                          onClick={() => handleClickAppoitment(it, item.day)}
                                          key={i}
                                          className={style.block}>{moment(it, "HH:mm:ss").format("HH:mm")}
                                          </div>
                                      )
                          })
                          }
                                </div>
                            </div>
                        ))}
                      </Slider>
                  </div>
                <div className={style.arrow}> <ArrowForwardIosIcon onClick={()=>SliderRef.current.slickNext()} />
                </div>
          </div>
          </div>
        )
      }
      else {
        return (
          <div>
            <div className={style.container}>
              <div className={style.acun}>Pas de rendez-vous réservable en-ligne pour ce praticien</div>
            </div>
          </div>
        )
      }
    }




    return (
    
    <Fragment>
      {displayAppoitments()}
      {modal &&
        <Modal object={object} doctor={doctor} />
      }
    </Fragment>
  )
}

