import React, { useEffect, useRef } from 'react'
import { Fragment } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { createTheme, ThemeProvider, makeStyles } from "@mui/material/styles";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";


import EmailIcon from '@mui/icons-material/Email';
import LockIcon from '@mui/icons-material/Lock';
import style from './Signup.module.css'
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { registerUser } from '../../redux/features/Auth/AuthSlice';

import pdf from '../../assets/documents/CONDITIONS D’UTILISATION-PA.pdf'
import dayjs from 'dayjs';
import { Button, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, TextField } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';


export default function Signup() {

  const dispatch = useDispatch()
  const navigate = useNavigate()


  const [acceptAgree, setAcceptAgree] = useState(false)
  const [saveId, setSaveId] = useState(false)

  const [validation, setValidation] = useState("");


  const form = useRef();


  const [data, setData] = useState({
    email : "",
    password : "",
    birth_day : dayjs().format('YYYY-MM-DD'),
  })
  
  
  
  const handleSubmit = () => {
    const age = dayjs().diff(data.birth_day, 'years', true)

    if ((data.password.length || data.password.length) < 6) {
      setValidation("Le mot de passe doit comporter au moins 6 caractères ");
      return;
    } else if (age < 18) {
      setValidation("Si vous avez moins de 18 ans, demandez à un parent de prendre rendez-vous pour vous depuis son compte Doctodz")
    }
    else {
      try {
        dispatch(registerUser(data)).unwrap()
        .then((originalPromiseResult) => {
          if (originalPromiseResult === 'succes') {
            navigate('/')
          } 
        })
        .catch((rejectedValueOrSerializedError) => {
          setValidation("Identifiants invalid");
        })
        // form.current.reset();
        setValidation("");
        // setAcceptAgree(!acceptAgree)
      } catch (error) {
        console.log(error);
      }
    }
  };



  const theme = createTheme({
    palette: {
      primary: {
        main: '#00a6fb',
        dark: '#00a6fb',
        contrastText: "white",
      },
    },
  });


  const handleChangeDate = (newValue) => {
    setData({...data, birth_day : dayjs(newValue).format('YYYY-MM-DD') })
  }


  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);


  const handleChangePassword = (elem) => {
    setData({...data, password : elem.target.value})
  }

  const handleChangeEmail = (email) => {
    data['email'] = email.target.value
  }


  return (
    <Fragment>
      <div className={style.page}>
        <div className={style.formConatiner}>
          <h2>Nouveau sur Doctodz ?</h2>
          <h3>Saisissez vos informations pour continuer.</h3>

          <TextField
              style={{ marginBottom : "30px" }}
              className={style.input} 
              onChange={email => handleChangeEmail(email)}
                label="Votre adresse émail"
              >
          </TextField>

                  
              <ThemeProvider theme={theme}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                  className={style.input}
                    label="Date de naissance"
                    value={data.birth_day}
                    onChange={value => handleChangeDate(value)}
                    renderInput={(params) => <TextField style={{ marginBottom :'20px' }} {...params} />}
                  />
                </LocalizationProvider>
              </ThemeProvider>

              <FormControl className={style.input} style={{ marginBottom : "20px" }} variant="outlined">
                <InputLabel htmlFor="outlined-adornment-password">Mot de passe</InputLabel>
                <OutlinedInput
                  onChange={(elem) => handleChangePassword(elem)}
                  id="outlined-adornment-password"
                  type={showPassword ? 'text' : 'password'}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Mot de passe"
                />
              </FormControl>


              <div className={style.input}>
                {validation &&
                  <div className={style.danger}>
                      <p>{validation}</p>
                  </div>
                }
                <div className={style.check_box}>
                  <input
                  onClick={() => setAcceptAgree(!acceptAgree)}
                  type="checkbox" id="check1" />
                  <label htmlFor="check2">J'accepte les <a 
                  href="https://photo-docteur-algerie-doctodz.s3.eu-west-3.amazonaws.com/informations-legal/patient/CONDITIONS+D%E2%80%99UTILISATION-PA.pdf"
                  target="_blank" >Conditions d'Utilisation de Doctodz</a></label>
                </div>
                {/* <div className={style.check_box}>
                  <input type="checkbox" id="check2" defaultChecked />
                  <label htmlFor="check2">Se souvenir de mon identifiant </label>
                </div> */}
                <div className={acceptAgree ? style.enabled : style.disabled}>
                  <input
                  onClick={handleSubmit}
                  disabled={!acceptAgree}
                  type="button" value="S'INSCRIRE" />
                </div>
                <div className={style.forgot}>
                  <Link to='/signup/'>
                  Vous avez déjà un compte ?
                  </Link>
                </div>
                <Button onClick={() => navigate('/signin')} style={{ background : "#4DC1FC", marginBottom : "39px" }} className={style.button} variant="contained">
                  Se connecter
                </Button>

              </div>

        </div>
              <div className={style.consentment}>
                <p>Vos données sont sécurisées et stockées chez AWS à Paris, un datacenter certifié HDS.</p>
                <p>تتم حفظ بياناتك بأمان في مركز بيانات أو دبليو إس في باريس، وهو معتمد بموجب إتش دي إس.</p>
              </div>
      </div>
    </Fragment>
  )
}
