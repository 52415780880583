import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Fragment } from 'react'
import Navbar from "../Navbar/Navbar";
import style from './Layout.module.css'
import Footer from "../Footer/Footer";
import { autoLogin, resetUser } from "../../redux/features/Auth/AuthSlice";
import { useLocation, useParams } from "react-router-dom";
import { trackPages } from "../../redux/features/Analitic/AnaliticSlice";

export default function Layout({children}) {

  
  const dispatch = useDispatch()




    const { user } = useSelector((state) => state.auth)
    let location = useLocation()
    const userData = {
      url : location.pathname,
      user : null,
    }
    if (user) {
        userData['user'] = user.id
    }
    useEffect(() => {
      // dispatch(trackPages(userData))
    }, [dispatch, location]);



  return (
    <Fragment>
      {/* <div className={style.container}> */}
          <div className={style.navbar}>
            <Navbar />
          </div>
            {children}
          <div className="footer">
            <Footer />
          </div>
      {/* </div> */}
    </Fragment>
  )
}
