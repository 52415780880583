import { Fragment, useEffect, useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import { useDispatch, useSelector } from 'react-redux';

import style from './Signin.module.css'
import LockIcon from '@mui/icons-material/Lock';


import { loginUser, resetUser } from '../../redux/features/Auth/AuthSlice';
import { Button, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, Paper, TextField } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

export default function Signin() {


  const dispatch = useDispatch()
  const navigate = useNavigate()


  const [data, setData] = useState({
    email: '',
    password: '',
  })

  const [validation, setValidation] = useState("")



  const handleSubmit = () => {
    setValidation('');

    if (data.email === '') {
      setValidation('Veuillez saisir votre adresse e-mail.');
      return;
    }
    
    if (data.password === '') {
      setValidation('Veuillez saisir votre mot de passe.');
      return;
    }


    dispatch(loginUser(data)).unwrap().then((Response) => {
        if (Response.refresh) {
          navigate('/')
        } else {
          setValidation('Une erreur est survenue. Veuillez vérifier votre compte et réessayer.')
        }
      })
    .catch((Error) => {
      setValidation('Une erreur est survenue. Veuillez vérifier votre compte et réessayer.')
    })
  }






  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleChangePassword = (elem) => {
    setData({...data, password : elem.target.value})
  }


  const handleChangeEmail = (email) => {
    setData({...data, email : email.target.value})
  }



  const handleClickSignup = () => {
    navigate('/signup/')
  }


  return (
    <Fragment>
      <div className={style.page}>
        <div className={style.formConatiner}>
          <h1>Connexion</h1>
          <TextField
              style={{ marginBottom : "30px" }}
              className={style.input} 
              onChange={email => handleChangeEmail(email)}
                label="Adresse émail"
              >
          </TextField>
          
          <FormControl 
          style={{ marginBottom : "30px" }}
          className={style.input} variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password">Mot de passe</InputLabel>
              <OutlinedInput
                onChange={(elem) => handleChangePassword(elem)}
                id="outlined-adornment-password"
                type={showPassword ? 'text' : 'password'}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Mot de passe"
              />
            </FormControl>

              {validation &&
                  <div className={style.danger}>
                      <p>{validation}</p>
                  </div>
              }

            <div className={style.forgot}>
              <Link to='/forgot-password/'>
                Mot de passe oublié ?
              </Link>
            </div>
          
          <Button
          onClick={handleSubmit}
          style={{ background : "#F87575", marginBottom : "47px" }} className={style.button} variant="contained">
            Se connecter
          </Button>
            <div className={style.forgot}>
              <Link to='/signup/'>
              Pas encore de compte ?
              </Link>
            </div>
          <Button onClick={handleClickSignup} style={{ background : "#4DC1FC", marginBottom : "25px" }} className={style.button} variant="contained">
            Créer mon compte
          </Button>
        </div>

      </div>
    </Fragment>
  )
}
