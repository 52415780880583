import React, { useEffect, useRef } from 'react'
import { Fragment } from 'react'
import useOutSideClick from '../Utils/Utils';

import GridCloseIcon from '../../assets/images/close.svg'
import SearchIcon from '@mui/icons-material/Search';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import image from '../../assets/images/doctor-avatar.jpeg'

import style from './ShareModal.module.css'
import { useDispatch, useSelector } from 'react-redux';
import { getDoctorListforPatient, getPatientList, getProfileDetails } from '../../redux/features/Profile/ProfileSlice';
import { useNavigate, useParams } from 'react-router-dom';
import { shareDocument, shareOrdonnance, shareReport } from '../../redux/features/Document/DocumentSlice';

export default function ShareModal({ setModal, formData }) {

    const popover = useRef();
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const params = useParams()
    const patient = params.profilid

    useOutSideClick(popover, () => setModal(false));

    const { doctorlist } = useSelector((state) => state.profile)


    console.log(doctorlist);


    useEffect(() => {
        dispatch(getDoctorListforPatient(patient))
      },  [dispatch]);

    const handleClickProfile = (el) => {
        // console.log(formData);
        formData['doctor'] = el.doctor.id
        if (formData.doctor) {
            if (formData.type === "Ordonance") {
                dispatch(shareOrdonnance(formData)).then((res) => {
                    setModal(false)
                })
            } else if (formData.type === 'Compte rendu') {
                dispatch(shareReport(formData)).then((el) => {
                    setModal(false)
                })
            }
        }
    }
    
    return (
        <Fragment>
            <div className={style.overlay}>
                <div ref={popover} className={style.modalContainer}>
                    <div className="img">
                        <img
                            className={style.closeBtn}
                            alt="close "
                            src={GridCloseIcon}
                            onClick={() => setModal(false)}
                            />
                        <div className={style.title}>
                            <h2>Partager avec votre Medcein</h2>
                        </div>
                    </div>
                    <div className={style.search}>
                        <SearchIcon />
                        <input type="text" placeholder="Partager avec votre Medcein" />
                    </div>
                    <div className={style.subtitle}>
                        <h3>tous vos médcein</h3>
                    </div>

                    {doctorlist && doctorlist.map((el, i) =>
                        <div onClick={() => handleClickProfile(el)} key={i} className={style.doctor}>
                            <div className={style.doc}>
                                <img src={image} alt="" />
                                <p>Dr {el.doctor.nom} {el.doctor.prenom} </p>
                            </div>
                            <div className="icon">
                                <ChevronRightIcon />
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </Fragment>
    )
}
