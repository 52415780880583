import React, { Fragment } from 'react'

import style from './AboutUs.module.css'

export default function AboutUs() {
  return (
    <Fragment>
    </Fragment>
  )
}
