import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";



// https://api.doctodz.com/

export const getAvailableAppointement = createAsyncThunk('appointments/getAvailableAppointement',
  async (id, thunkAPI) => {
    const {rejectWithValue} = thunkAPI;
    try {
      const res = await fetch(`https://api.doctodz.com/api/appointments/listavailabletimeslot/${id}/`, {
        method: 'GET',
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await res.json();
      return data
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);






export const createAppoitment = createAsyncThunk('appointments/createAppoitment',
  async (userData, thunkAPI) => {
    const {rejectWithValue} = thunkAPI;
    try {
        const res = await fetch("https://api.doctodz.com/api/appointments/appoitmentcreate/", {
          method: 'POST',
          body: JSON.stringify(userData),
          headers: {
            'Content-Type': 'application/json; charset=utf',
            "Authorization": `token ${localStorage.getItem('token')}`,
            "Accept": "application/json",
          },
        });
        const data = await res.json();
        return data
      } catch (error) {
          return rejectWithValue(error.message);
      }
    }
);




export const upadateAppoitment = createAsyncThunk('appointments/upadateAppoitment',
  async (userData, thunkAPI) => {
    const {rejectWithValue} = thunkAPI;
    const { id } = userData;
    try {
        const res = await fetch(`https://api.doctodz.com/api/appointments/appoitmentupdate/${id}/`, {
          method: 'PUT',
          body: JSON.stringify(userData),
          headers: {
            'Content-Type': 'application/json; charset=utf',
            "Authorization": `token ${localStorage.getItem('token')}`,
            "Accept": "application/json",
          },
        });
        const data = await res.json();
        return data
      } catch (error) {
          return rejectWithValue(error.message);
      }
    }
);


export const getHistoriqueAppoitments = createAsyncThunk('appointments/getHistoriqueAppoitments',
  async (patient, thunkAPI) => {
    const {rejectWithValue} = thunkAPI;
    try {
      const res = await fetch(`https://api.doctodz.com/api/appointments/appoitmentlistinpatient/${patient}/`, {
        method: 'GET',
        headers: {
          "Content-Type": "application/json",
          "Authorization": `token ${localStorage.getItem('token')}`,
          "Accept": "application/json",
        },
      });
      const data = await res.json();
      return data
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);


export const getNextHistorique = createAsyncThunk('appointments/getNextHistorique',
  async (patient, thunkAPI) => {
    const {rejectWithValue} = thunkAPI;
    try {
      const res = await fetch(`https://api.doctodz.com/api/appointments/appoitmentnextinpatient/${patient}/`, {
        method: 'GET',
        headers: {
          "Content-Type": "application/json",
          "Authorization": `token ${localStorage.getItem('token')}`,
          "Accept": "application/json",
        },
      });
      const data = await res.json();
      return data
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);



export const getAppoitmentDetails = createAsyncThunk('appointments/getAppoitmentDetails',
  async (appData, thunkAPI) => {
    const { patient, appoitment } = appData
    const {rejectWithValue} = thunkAPI;
    try {
      const res = await fetch(`https://api.doctodz.com/api/appointments/appoitmentdetails/${appoitment}/${patient}/`, {
        method: 'GET',
        headers: {
          "Content-Type": "application/json",
          "Authorization": `token ${localStorage.getItem('token')}`,
          "Accept": "application/json",
        },
      });
      const data = await res.json();
      return data
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);



const AppointmentsSlice = createSlice({
    name: 'appointments',
    initialState : {
        availableappointement: [],
        historique: [],
        createdappoitment: "",
        isLoading: false,
        error: null,
    },
    reducers: {},
    extraReducers: {
        // loadUser user
        [getAvailableAppointement.pending]: (state, action) => {
            state.isLoading = true;
            state.error = null;
        },
        [getAvailableAppointement.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.availableappointement = action.payload;
        },
        [getAvailableAppointement.rejected]: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        // create appoitment 
        [createAppoitment.pending]: (state, action) => {
            state.isLoading = true;
            state.error = null;
        },
        [createAppoitment.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.createdappoitment = action.payload;
        },
        [createAppoitment.rejected]: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        // upadateAppoitment appoitment 
        [upadateAppoitment.pending]: (state, action) => {
            state.isLoading = true;
            state.error = null;
        },
        [upadateAppoitment.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.createdappoitment = action.payload;
        },
        [upadateAppoitment.rejected]: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        // getHistoriqueAppoitments appoitment 
        [getHistoriqueAppoitments.pending]: (state, action) => {
            state.isLoading = true;
            state.error = null;
        },
        [getHistoriqueAppoitments.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.historique = action.payload;
        },
        [getHistoriqueAppoitments.rejected]: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        // getHistoriqueAppoitments appoitment 
        [getNextHistorique.pending]: (state, action) => {
            state.isLoading = true;
            state.error = null;
        },
        [getNextHistorique.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.historique = action.payload;
        },
        [getNextHistorique.rejected]: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        // getAppoitmentDetails
        [getAppoitmentDetails.pending]: (state, action) => {
          state.isLoading = true;
          state.error = null;
        },
        [getAppoitmentDetails.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.createdappoitment = action.payload;
        },
        [getAppoitmentDetails.rejected]: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },
    },
});


// export const { agreeFunc } = authSlice.actions;

export default AppointmentsSlice.reducer;