import React from 'react'
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import { useState } from "react"
import { Fragment } from 'react'

import styles from './AddProfile.module.css'
import { createProfile } from '../../../redux/features/Profile/ProfileSlice';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';


export default function AddProfile() {
    const [error , setError] = useState('');
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [formData, setFormData] = useState({
        nom: "",
        prenom: "",
        sexe: "",
        nom_de_naissance: "",
        date_de_naissance: "",
        pays_de_naissance: "",
        ville_de_naissance: "",
        email: "",
        phone_number:"",
    })

    const HandleSubmit = (e) =>{
        e.preventDefault();
        if (formData.nom && formData.prenom && formData.ville_de_naissance && formData.date_de_naissance && formData.phone_number && formData.pays_de_naissance && formData.sexe && formData.nom_de_naissance && formData.email) 
            {
            setError(null)
            dispatch(createProfile(formData)).then((el) => {
                const newprofile = el.payload.data.id
                navigate(`/${newprofile}/coordonne/`)
                // setFormData({
                //     nom: "",
                //     civ:"",
                //     prenom: "",
                //     sexe: "",
                //     nom_de_naissance: "",
                //     date_de_naissance: "",
                //     pays_de_naissance: "",
                //     ville_de_naissance: "",
                //     email: "",
                //     phone_number:"",
                // })
            })
            }
        else {
            setError('Veuillez Remplire tout les champs du formulaire')
        }   
    }




  return (
    <Fragment>
            <div className={styles.container}>
                <form onSubmit={HandleSubmit} >
                    <h1>Coordonnées</h1>
                    <label htmlFor="sexe">Sexe</label>
                    <div className={`${styles.input} ${styles.sexe}`}>
                        <div className={styles.radio}>
                        <input onChange={(e)=>setFormData({...formData , sexe:e.target.value })} type="radio" id='Homme' name='sexe' value="Homme" />
                        <label htmlFor="Homme">Homme</label>
                        </div>
                        <div className={styles.radio}>
                        <input onChange={(e)=>setFormData({...formData , sexe:e.target.value })} type="radio" id='Femme' name='sexe' value="Femme" /> 
                        <label htmlFor="Femme">Femme</label>
                        </div>
                    </div>
                    <div className={styles.centerContainer}>
                    <div className={styles.LeftContainer}>
                    <label htmlFor="nom">Nom</label>
                    <div className={`${styles.input} ${styles.nom}`}>
                        <input onChange={(e)=>setFormData({...formData , nom:e.target.value })} value={formData.nom} type="text" placeholder='Nom'  />
                    </div>
                    <label htmlFor="nomNais">Nom de naissance</label>
                    <div className={`${styles.input} ${styles.nomNais}`}>
                        <input onChange={(e)=>setFormData({...formData , nom_de_naissance:e.target.value })} value={formData.nom_de_naissance} type="text" name='nomNais' placeholder='Nom de naissance' />
                    </div>
                    <label htmlFor="paysNais">Pays de naissance</label>
                    <div className={`${styles.input} ${styles.paysNais}`}>
                        <input onChange={(e)=>setFormData({...formData , pays_de_naissance:e.target.value })} type="text" name='paysNais' value={formData.pays_de_naissance} placeholder='Nom de naissance' />
                    </div>
                    <label htmlFor="numTel">Telephone portable</label>
                    <div className={`${styles.input} ${styles.numTel}`}>
                        <LocalPhoneIcon />
                        <input style={{paddingLeft : "10px"}} value={formData.phone_number} onChange={(e)=>setFormData({...formData , phone_number:e.target.value })} type="text" name='numTel' placeholder='Téléphone portable' />
                    </div>
                    </div>
                    <div className={styles.rightContainer}>
                    <label htmlFor="prenom">Prénom</label>
                    <div className={`${styles.input} ${styles.prenom}`}>
                        <input value={formData.prenom} onChange={(e)=>setFormData({...formData , prenom:e.target.value })} type="text" placeholder='Prénom' />
                    </div>
                    <label htmlFor="dateNais">Date de naissance</label>
                    <div className={`${styles.input} ${styles.dateNais}`}>
                        <input value={formData.date_de_naissance} onChange={(e)=>setFormData({...formData , date_de_naissance:e.target.value })} type="date" name='dateNais' placeholder='Date de naissance' />
                    </div>
                    <label htmlFor="villeNais">Ville de naissance</label>
                    <div className={`${styles.input} ${styles.villeNais}`}>
                        <LocationOnOutlinedIcon />
                        <input value={formData.ville_de_naissance} onChange={(e)=>setFormData({...formData , ville_de_naissance:e.target.value })} style={{paddingLeft:"8px"}} type="text" name='villeNais' placeholder='Ville de naissance' />
                    </div>
                    <label htmlFor="adrEmail">Adresse email</label>
                    <div className={`${styles.input} ${styles.adrEmail}`}>
                        <MailOutlineIcon fontSize="medium" />
                        <input value={formData.email} onChange={(e)=>setFormData({...formData , email:e.target.value })} style={{paddingLeft:"10px"}} type="email" name='adrEmail' placeholder='Adresse email'  />
                    </div>
                    </div>
                    </div>
                    {error ? (
                    <div className={styles.error}>
                        <p>* {error}</p>
                    </div>
                    ) : null }
                    <div className={styles.button}>
                        <input type='button' className={styles.Annuler} value='Annuler' />
                        <input type='submit' className={styles.submit} value='Submit' />
                    </div>        
                </form>
            </div>
    </Fragment>
    )
}
