import React, { useEffect } from 'react'
import { Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, Link, useNavigate } from 'react-router-dom'
import { useState } from 'react'

import SearchBar from '../../Components/SearchBar/SearchBar'
import { getFiltredDoctor, getDoctorByExpertise } from '../../redux/features/Doctor/DoctorSlice'

import image1 from '../../assets/images/doctor-avatar.jpeg'

import style from './DoctorList.module.css'
import DateRangeIcon from '@mui/icons-material/DateRange';

import { Button, CircularProgress, LinearProgress } from '@mui/material'
import Map from '../../Components/Map/Map'



export default function DoctorList() {


  
  const { filtreddoctor, isLoading } = useSelector((state) => state.doctor)

  
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const params = useParams()


  const [data, setData] = useState({ 
    villeid : parseInt(params.villeid),
    specid : parseInt(params.specid),
    isExepertise : parseInt(params.isExepertise),
  })



  // useEffect(() => {
  //   if (data.isExepertise !== 0) {
  //     dispatch(getDoctorByExpertise(data))
  //   } else if (data.isExepertise === 0) {
  //     dispatch(getFiltredDoctor(data))
  //   }
  // },  [dispatch,]);




  const handleShowAppoitment = (item) => {
    navigate(`/${item.slug}/${item.id}/`)
  }



  return (
    <Fragment>
        <div className={style.serachbar} >
          <SearchBar data={data} setData={setData} />
        </div>
        <div className={style.filterBar}></div>
          <div className={style.doctorlist}>
            <div className={style.container}>

              {isLoading ?
                <div className={style.loadincards} >
                  <CircularProgress disableShrink />
                </div>
                :
                <div className={style.cards}>
                    {filtreddoctor && filtreddoctor.map((item, i) => 
                    <Link key={i} to={`/${item.slug}/${item.id}/`} >
                        <div className={style.card}>
                          <div className={style.doctor}>
                            <div className="img">
                              <img src={image1} alt="doctor_picture" />
                            {/* {item.photo  === "https://photo-docteur-algerie-doctodz.s3.amazonaws.com/" ?
                              <img src={image1} alt="doctor_picture" /> 
                              : item.photo === null ?
                              <img src={image1} alt="doctor_picture" />
                              :
                              <img src={item.photo} alt="doctor_picture" />
                            } */}
                            </div>
                            <div className={style.text}>
                              <h2>{item.nom}</h2>
                              <h3>{item.specilite}</h3>
                              <p>{item.adresse}, {item.ville} {item.code}</p>
                            </div>
                          </div>
                          <div className={style.button}>
                          {item.availabletimeslot !== "2100-03-30" ?
                            <Button
                                onClick={() => handleShowAppoitment(item)}
                                startIcon={<DateRangeIcon />}
                                variant="contained" >
                                  Rendez-vous disponible a partir du {item.availabletimeslot}
                            </Button>
                              :
                            <Button
                                style={{ background : "#bfc1c3" }}
                                onClick={() => handleShowAppoitment(item)}
                                variant="contained" >
                                  Pas de rendez-vous réservable en-ligne pour ce praticien
                            </Button>
                          }
                          </div>
                        </div>
                    </Link>
                    )}
                </div>
              }


                <div className={style.mapContainer}>
                  <div className={style.map}>
                    <Map data={filtreddoctor} />
                  </div>
                </div>
              </div>
          </div>

    </Fragment>
  )
}
