import { Login } from '@mui/icons-material'
import React from 'react'
import { useState } from 'react'
import { Fragment } from 'react'
import { useSelector } from 'react-redux'
import ChoixMotif from './ChoixMotif/ChoixMotif'
import Finish from './Finish/Finish'
import Indentification from './Indentification/Indentification'
import InfoPatient from './InfoPatient/InfoPatient'

import style from './Modal.module.css'
import Verification from './Verification/Verification'

export default function Modal({ object, doctor }) {


    const { isAuthenticated } = useSelector((state) => state.auth)


    const [page, setPage] = useState(0)
    const [formData, setFormData] = useState({
        timestart : object.start,
        timeend : object.end,
        timeid : object.id,
        date : object.day,
        created_by : doctor,
        patient : '',
        motif_consultation : '',
    })

    const title = ['Choix de Motif', 'Indentification' ,'Vérification', 'Info Patient', "C'est terminé"]

    const displayComponent = () => {

        if (page === 0) {
            return (
                <ChoixMotif
                formData={formData} setFormData={setFormData} 
                doctor={doctor} setPage={setPage} />
            )
        } else if (page === 1) {
            if (!isAuthenticated) {
                return (
                    <Indentification setPage={setPage} />
                )
            } else if (isAuthenticated) {
                setPage(2)
            }
        } else if (page === 2) {
            return (
                <Verification setPage={setPage} object={object}
                                doctor={doctor} formData={formData} />
            )
        } else if (page === 3) {
            return (
                <InfoPatient setPage={setPage} formData={formData} setFormData={setFormData} />
            )
        } else if (page === 4) {
            return (
                <Finish />
            )
        }
    }


  return (
    <Fragment>
      <div className={style.overlay}>
        <div className={style.title}>
                <h1>{title[page]}</h1>
        </div>
        <div className={style.progress_bar}>
            <div className={style.progress} 
            style={{ width: page === 0 ? '20%' : page === 1 ? '40%' : page === 2 ? '60%' : page === 3 ? '80%' : "100%" }} ></div>
        </div>
        <div className="component">
            {displayComponent()}
        </div>
      </div>
    </Fragment>
  )
}
