import React, { useEffect, useState } from 'react'
import { Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createAppoitment } from '../../../../redux/features/Appointments/AppointmentsSlice'
import { upadatePatientList } from '../../../../redux/features/Doctor/DoctorSlice'
import { getPatientList } from '../../../../redux/features/Profile/ProfileSlice'
import DetailsCalendar from '../../../DetailsCalendar/DetailsCalendar'


import style from  './ChoiPatient.module.css'

export default function ChoiPatient({ setPage, setFormPage, formData, setFormData }) {


  const { profilelist } = useSelector((state) => state.profile)
  
  const dispatch = useDispatch()


  const [validation, setValidation] = useState("");
  


  const userData = {
    doctor : formData.created_by,
    patient : formData.patient
  }

    const handleClickConfirm = () => {
        dispatch(createAppoitment(formData)).unwrap()
        .then((originalPromiseResult) => {
          if (originalPromiseResult !== "creno plus disponible") {
              dispatch(upadatePatientList(userData)).then(() => {
              setPage(4)
            })
          } else if (!originalPromiseResult) {
            console.log('not created');
          }
          else if (originalPromiseResult === "creno plus disponible") {
            setValidation("ce rendez-vous n’est plus disponible veuillez choisir un autre svp")
          }
        })
        .catch((rejectedValueOrSerializedError) => {
          // handle error here
        })
    }
    
    const handleChangeProfile = (e) => {
      setFormData({...formData, patient : e.target.value})
    }


    useEffect(() => {
      dispatch(getPatientList())
    },  [dispatch]);


  return (
    <Fragment>
      {validation ?
        <div className={style.validation}>
              <h5>{validation}</h5>
              <DetailsCalendar />
        </div>
        :
        <Fragment>
          <div className={style.container} >
            <div className={style.choixprofile} >
              <div className="title">
                <h2>Pour qui prenez vous ce rendez-vous ?</h2>
              </div>
              <div className={style.liste}>
                <form action="">
                  {profilelist && profilelist.map((el, i) =>
                    <div key={i}>
                      <input
                        onChange={(e) => handleChangeProfile(e)}
                        type="radio" id={el.id} name="profile" value={el.id} />
                      <label htmlFor={el.id}>{el.nom} {el.prenom}</label>
                    </div>
                  )}
                </form>
              </div>
              <div className={style.button}>
                <button
                onClick={() => setFormPage(1)}
                >AJOUTER UN PROCHE</button>
              </div>
            </div>

            <div className={style.choixprofile} >
              <div className={formData.patient ? style.enable : style.confirm}>
                <button
                  disabled={formData.patient ? false : true}
                  onClick={handleClickConfirm}
                >CONFIRMER LE RENEZ-VOUS</button>
                <p>En comfirmant ce rendez-vous, vous vous engagez a l’honorer. <span>Pensez bien a annuler le plus tôt ppossible en cas d’imprevu</span></p>
              </div>
            </div>
          </div>
        </Fragment>
      }

    </Fragment>
  )
}
