import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";



export const getTarifs = createAsyncThunk('doctor/getTarifs',
  async (doctor, thunkAPI) => {
    const {rejectWithValue} = thunkAPI;
    try {
      const res = await fetch(`https://api.doctodz.com/api/doctorfolder/tariflist/${doctor}/`, {
        method: 'GET',
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await res.json();
      return data
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);



export const getFiltredDoctor = createAsyncThunk('doctor/getFiltredDoctor',
  async (dataa, thunkAPI) => {
    const {rejectWithValue} = thunkAPI;
    const { villeid, specid } = dataa;
    try {
      const res = await fetch(`https://api.doctodz.com/api/doctorfolder/doctorfiltredlist/${specid}/${villeid}/`, {
        method: 'GET',
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await res.json();
      return data
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);


export const getDoctorByExpertise = createAsyncThunk('doctor/getDoctorByExpertise',
  async (dataa, thunkAPI) => {
    const {rejectWithValue} = thunkAPI;
    const { villeid, specid } = dataa;
    try {
      const res = await fetch(` https://api.doctodz.com/api/doctorfolder/doctors/by_expertise/${specid}/${villeid}/`, {
        method: 'GET',
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await res.json();
      return data
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);



export const getSpeciality = createAsyncThunk('doctor/getSpeciality',
  async (_, thunkAPI) => {
    const {rejectWithValue} = thunkAPI;
    try {
      const res = await fetch('https://api.doctodz.com/api/doctorfolder/specialitylist/', {
        method: 'GET',
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await res.json();
      return data
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);


export const getExpertise = createAsyncThunk('doctor/getExpertise',
  async (_, thunkAPI) => {
    const {rejectWithValue} = thunkAPI;
    try {
      const res = await fetch('https://api.doctodz.com/api/doctorfolder/expertiselist/', {
        method: 'GET',
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await res.json();
      return data
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);



export const getDoctorList = createAsyncThunk('doctor/getDoctorList',
  async (_, thunkAPI) => {
    const {rejectWithValue} = thunkAPI;
    try {
      const res = await fetch('https://api.doctodz.com/api/doctorfolder/doctorlist/', {
        method: 'GET',
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await res.json();
      return data
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);



export const getDoctorDetails = createAsyncThunk('doctor/getDoctorDetails',
  async (id, thunkAPI) => {
    const {rejectWithValue} = thunkAPI;
    try {
      const res = await fetch(`https://api.doctodz.com/api/doctorfolder/doctordetails/${id}/`, {
        method: 'GET',
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await res.json();
      return data
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const upadatePatientList = createAsyncThunk('doctor/upadatePatientList',
  async (userData, thunkAPI) => {
    const {rejectWithValue} = thunkAPI;
    try {
        const res = await fetch(`https://api.doctodz.com/api/doctorfolder/patientlistappoit/`, {
          method: 'POST',
          body: JSON.stringify(userData),
          headers: {
            'Content-Type': 'application/json; charset=utf',
            "Authorization": `token ${localStorage.getItem('token')}`,
            "Accept": "application/json",
          },
        });
        const data = await res.json();
        return data
      } catch (error) {
          return rejectWithValue(error.message);
      }
    }
);




const DoctorSlice = createSlice({
    name: 'doctor',
    initialState : {
        doctorlist: [],
        filtreddoctor: [],
        speciality: [],
        expertise: [],
        tarifs: [],
        doctor_details: {},
        isLoading: false,
        error: null,
    },
    reducers: {},
    extraReducers: {
        // get tarifs
        [getTarifs.pending]: (state, action) => {
            state.isLoading = true;
            state.error = null;
        },
        [getTarifs.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.tarifs = action.payload;
        },
        [getTarifs.rejected]: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        // loadUser doctor  details
        [getDoctorDetails.pending]: (state, action) => {
            state.isLoading = true;
            state.error = null;
        },
        [getDoctorDetails.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.doctor_details = action.payload;
        },
        [getDoctorDetails.rejected]: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        // loadUser filtred doctor 
        [getFiltredDoctor.pending]: (state, action) => {
            state.isLoading = true;
            state.error = null;
        },
        [getFiltredDoctor.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.filtreddoctor = action.payload;
        },
        [getFiltredDoctor.rejected]: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        // loadUser filtred doctor 
        [getDoctorByExpertise.pending]: (state, action) => {
            state.isLoading = true;
            state.error = null;
        },
        [getDoctorByExpertise.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.filtreddoctor = action.payload;
        },
        [getDoctorByExpertise.rejected]: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        // load speciality
        [getSpeciality.pending]: (state, action) => {
            state.isLoading = true;
            state.error = null;
        },
        [getSpeciality.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.speciality = action.payload;
        },
        [getSpeciality.rejected]: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        // load speciality
        [getExpertise.pending]: (state, action) => {
            state.isLoading = true;
            state.error = null;
        },
        [getExpertise.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.expertise = action.payload;
        },
        [getExpertise.rejected]: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        // load getDoctorList
        [getDoctorList.pending]: (state, action) => {
            state.isLoading = true;
            state.error = null;
        },
        [getDoctorList.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.doctorlist = action.payload;
        },
        [getDoctorList.rejected]: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        // update patietn list
        [upadatePatientList.pending]: (state, action) => {
            state.isLoading = true;
            state.error = null;
        },
        [upadatePatientList.fulfilled]: (state, action) => {
            state.isLoading = false;
            // state.doctorlist = action.payload;
        },
        [upadatePatientList.rejected]: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },
    },
});


// export const { agreeFunc } = authSlice.actions;

export default DoctorSlice.reducer;