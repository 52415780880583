import { Fragment, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, Outlet, useLocation, useNavigate, useParams } from 'react-router-dom'

import logo from '../../assets/images/logo-doctodz-blanc.svg'
import style from './Navbar.module.css'


import PersonIcon from '@mui/icons-material/Person';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useState } from 'react'
import { logoutUser, resetUser } from '../../redux/features/Auth/AuthSlice'
import useOutSideClick from '../Utils/Utils'
import { Button, MenuItem, MenuList, Paper } from '@mui/material'




export default function Navbar() {


  const { isAuthenticated } = useSelector((state) => state.auth)

  const [modal, setModal] = useState(false)

  const dispatch = useDispatch()
  const navigate = useNavigate()

    const popover = useRef();

    useOutSideClick(popover, () => setModal(false));


  const handleLougout = () => {
    dispatch(logoutUser())
    dispatch(resetUser())
    navigate('/')
  }




  const hadleClickAccount = () => {
    navigate(`/account`)
    setModal(false)
  }
  
  
  const handleConnect = () => {
    navigate(`/signin/`)
    setModal(false)
  }

  return (
      <Fragment>
        <div className={style.navbar}>
          <Link to={'/'}>
            <div className={style.image}>
                <img src={logo} alt="logo doctodz" />
            </div>
          </Link>

            {isAuthenticated ?
              <div className={style.button_container}>
                <div onClick={() => setModal(!modal)} className={style.account}>
                      <Button variant="outlined" style={{ color : "#fff" , outline : "#fff", border : "#fff" }} startIcon={<PersonIcon />}>
                        Mon Compte
                      </Button>
                </div>
                {modal &&
                

                    <Paper className={style.paper} ref={popover}>
                      <MenuList>
                        <MenuItem onClick={hadleClickAccount} >Profile</MenuItem>
                        <MenuItem style={{ color : "#c70000" }} onClick={handleLougout} >Déconnexion</MenuItem>
                      </MenuList>
                    </Paper>
                }
              </div>
              :
              <Fragment>
                <div className={style.button}>
                  <Button
                  onClick={handleConnect}
                  style={{ color : "#fff" }}
                  variant="text" startIcon={<PersonIcon />}>
                      Connexion / Inscription
                  </Button>
                </div>
                <div onClick={handleConnect} className={style.icon}>
                  <AccountCircleIcon />
                </div>
              </Fragment>
            }
        </div>


        
    </Fragment>
  )
}
