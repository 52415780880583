import React, { useEffect } from 'react'
import { Fragment } from 'react'

import style from './Appoitment.module.css'

import moment from 'moment'

import image from '../../assets/images/doctor-avatar.jpeg'


import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { useNavigate, useParams } from 'react-router-dom';
import ShareIcon from '@mui/icons-material/Share';
import MedicationIcon from '@mui/icons-material/Medication';
import OpenInBrowserIcon from '@mui/icons-material/OpenInBrowser';
import { getHistoriqueAppoitments, getNextHistorique } from '../../redux/features/Appointments/AppointmentsSlice';
import { useDispatch, useSelector } from 'react-redux';


export default function Appoitment() {


  const { historique } = useSelector((state) => state.appointments)

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const params = useParams()
  const patient = params.profilid


  const handleClickEvent = (item) => {
    if (item.type === "Consultation") {
      navigate(`/${patient}/appoitment/${item.id}/`)
    } else if (item.type === "Rendez-vous") {
      navigate(`/${patient}/appoitment/app/${item.id}/`)
    }
  }


  const handleClickShow = () => {
    dispatch(getHistoriqueAppoitments(patient))
  }


  useEffect(() => {
    dispatch(getNextHistorique(patient))
  },  [dispatch]);

  return (
    <Fragment>
      <div className={style.documents}>
        <div className={style.showappoitment}>
          <button onClick={handleClickShow}>afficher les rendez-vous passé</button>
        </div>
        {historique && historique.map((el, i) =>
        <Fragment key={i} >
            <div className={style.date}>
              <h2>{el.dateList}</h2>
            </div>
              {el.data && el.data.map((item, i) => 
                
                <div
                onClick={() => handleClickEvent(item)} key={i} className={style.document}>
                  <div className={style.it}>
                    <CalendarMonthIcon />
                    {item.annulé ?
                      <span>{item.type}  annulé </span>
                      :
                      <span>{item.type}</span>
                    }
                  </div>
                  <div className={style.it}>
                    <span>{item.date}</span>
                  </div>
                  <div className={style.it}>
                    <span>Dr {item.doctor}</span>
                  </div>
                  <div className={style.it}>
                    <span>A {moment(item.time, "HH:mm a").format('HH:mm')}</span>
                  </div>
                  {/* <div className={style.it} className={style.share}>
                    <OpenInBrowserIcon />
                    <span>Ouvrir</span>
                  </div> */}
                </div>
              )}
        </Fragment>
        )}
      </div>
    </Fragment>
  )
}
