import React, { useEffect } from 'react'
import { Fragment } from 'react'



import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import PersonIcon from '@mui/icons-material/Person';
import RestoreIcon from '@mui/icons-material/Restore';
import CloseIcon from '@mui/icons-material/Close';
import imgDoctor from '../../assets/images/doctor-avatar.jpeg'

import moment from 'moment';

import style from './AppoitmentsDetails.module.css'
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom'
import { getAppoitmentDetails, upadateAppoitment } from '../../redux/features/Appointments/AppointmentsSlice';

export default function AppoitmentsDetails() {

    const { createdappoitment } = useSelector((state) => state.appointments)
    const dispatch = useDispatch()

    const params = useParams()
    const patient = params.profilid
    const appoitment = params.appoitmentid

    const appData = {
        patient : patient, 
        appoitment : appoitment
    }


    useEffect(() => {
        dispatch(getAppoitmentDetails(appData))
    },  [dispatch]);


    const DeacUserData = {
        id : appoitment,
        alerte : 'False',
    }
    
    const userData = {
        id : appoitment,
        alerte : 'True',
      }


      const cancel = {
        id : appoitment,
        annuler : 'True',
      }



    const handleClickDeActivate = () => {
        dispatch(upadateAppoitment(DeacUserData))
    }


  const handleClickActivate = () => {
    dispatch(upadateAppoitment(userData))
  }


  const handleAnnuller = () => {
    dispatch(upadateAppoitment(cancel))
  }


    return (
    <Fragment>
        {createdappoitment &&
            <div className={style.container}>
                <div className="details">
                <div className={style.cardAppoitment}>
                    <div className={style.header}>
                        <div className={style.gggg}>
                            <CalendarMonthIcon />
                            <p>{createdappoitment.date}</p>
                        </div>
                        <div className={style.gggg}>
                            <AccessAlarmIcon />
                            <p>{moment(createdappoitment.from_time, "HH:mm a").format('HH:mm')}</p>
                        </div>
                    </div>
        
                    <div className={style.docDetails}>
                    <div className={style.image}>
                        {createdappoitment.created_by.photo ?
                            <img src={createdappoitment.created_by.photo} alt="" />
                            :
                            <PersonIcon />
                        }
                    </div>
                    <div className={style.text}>
                        <h2>Dr {createdappoitment.created_by.nom} {createdappoitment.created_by.prenom} </h2>
                        <h3>{createdappoitment.created_by.specilite.nom}</h3>
                    </div>
                    </div>
                    {!createdappoitment.annuler ?
                        <Fragment>
                            <div className={style.appoitBottun}>
                                {/* <div className={style.but2}>
                                    <RestoreIcon />
                                    <button>DEPLACER LE RDV</button>
                                </div> */}

                                <div
                                    onClick={handleAnnuller}
                                    className={style.but}>
                                        <CloseIcon />
                                        <button>ANNULLER LE RDV</button>
                                </div>
                            </div>
                            <div className={style.alerte}>
                                <AccessAlarmIcon />
                                <div className={style.textAletre}>
                                    <div className={style.deactivate}>
                                    <h2>Vous souhaitez rendre rendez-vous plutot ?</h2>
                                    </div>
                                    <h3>Activé une alerte et nous vous préviendrons si une disponibilité plus proche se libère</h3>
                                </div>
                                {createdappoitment.alerte ?
                                    <button
                                    onClick={handleClickDeActivate}
                                    >
                                        DESACTIVER L’ALERTE
                                            <ToggleOnIcon style={{ color : '#209F20' }} />
                                        </button>
                                    :
                                    <button
                                    onClick={handleClickActivate}
                                    >
                                        ACTIVER L’ALERTE
                                            <ToggleOffIcon />
                                    </button>
                                    }
                            </div>
                            <div className={style.alire}>
                                <WarningAmberIcon />
                                <h2>A lire avant otre prochain rendez-vous</h2>
                            </div>
                        </Fragment>
                        :
                        <div className={style.note}>
                            <p>votre rendez vous a été annulé</p>
                        </div>
                    }
                    </div>
                    </div>
                </div>
        }
    </Fragment>
    )
}
