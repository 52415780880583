import { Google } from '@mui/icons-material'
import moment from 'moment'
import React from 'react'
import { Fragment } from 'react'
import { Link } from 'react-router-dom'
import logo from '../../assets/images/docto-de-logo-navbar.png'


import FacebookIcon from '@mui/icons-material/Facebook';
import YouTubeIcon from '@mui/icons-material/YouTube';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';


import style from './Footer.module.css'


export default function Footer() {
  return (
    <Fragment>
        <div className={style.footer}>
            <div className={style.trait}></div>
            <div className={style.image_par}>
              <div className={style.image}>
                  <img src={logo} alt="logo DoctoDz" />
              </div>
              <p>Copyright © {moment().format('YYYY')} Doctodz, tous droits réservés.</p>
            </div>
            <div className={style.informations}>
              <div className={style.footersection}>
                <h2>A propos de DoctoDz</h2>
                <div className={style.links}>
                  <Link to='/aboutus' >
                    Qui sommes-nous ?
                  </Link>
                </div>
              </div>
              <div className={style.footersection}>
                <h2>Retrouvez-nous</h2>
                <div className={style.social_links}>
                  <div className={style.item}>
                    <a href="https://www.facebook.com/doctodzsolution" target="_blank">
                    <FacebookIcon />
                    </a>
                  </div>
                  <div className={style.item}>
                    <a href="https://www.youtube.com/@doctodz" target="_blank">
                    <YouTubeIcon />
                    </a>
                  </div>
                  <div className={style.item}>
                    <a href="https://twitter.com/doctodz" target="_blank">
                    <TwitterIcon />
                    </a>
                  </div>
                  <div className={style.item}>
                    <a href="https://www.instagram.com/doctodz" target="_blank">
                    <InstagramIcon />
                    </a>
                  </div>
                  <div className={style.item}>
                    <a href="https://www.linkedin.com/company/doctodz/" target="_blank">
                    <LinkedInIcon />
                    </a>
                  </div>
                </div>
              </div>
              <div className={style.footersection}>
                <h2>Mentions légales et CGU</h2>
                <div className={style.links}>
                  <p>Doctodz, société à responsabilité limitée, inscrite au CNRC de oran sous le numéro 31:00 - 0119385 B23 , dont le siège social est 05 Rue Hadj Freh Daiffallah, Oran 31000 Algérie.</p>
                  <a href="https://photo-docteur-algerie-doctodz.s3.eu-west-3.amazonaws.com/informations-legal/patient/CONDITIONS+D%E2%80%99UTILISATION-PA.pdf" target="_blank" >Conditions d'utilisation</a>
                  <a href="https://photo-docteur-algerie-doctodz.s3.eu-west-3.amazonaws.com/informations-legal/patient/Politique+de+protection+des+donn%C3%A9es+%C3%A0+caract%C3%A8re+personnel.pdf" target="_blank" >Politique de protection des données à caractère personnel</a>
                </div>
              </div>
            </div>
        </div>
    </Fragment>
  )
}
