import React, { Fragment } from 'react'

import 'leaflet/dist/leaflet.css';
import icon from "leaflet/dist/images/marker-icon.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";
import L from "leaflet";



import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";

export default function Map({ data }) {

    let DefaultIcon = L.icon({
        iconUrl: icon,
        shadowUrl: iconShadow,
      });
      L.Marker.prototype.options.icon = DefaultIcon;
  
  


      // let carIcon = L.icon({
      //   iconUrl: icon2,
      //   shadowUrl: iconShadow,
      // });
      // L.Marker.prototype.options.icon = carIcon;
  
    //   const coords = [
    //       { latitude: 35.6809676, longitude: -0.6553955},
    //       { latitude: 35.655, longitude: -0.6553955},
    //   ]
  


      const position = [35.7, -0.633333]


  return (
    <Fragment>
        <div>
            <MapContainer style={{ width : "400px", height : "400px" }} center={position} zoom={12} scrollWheelZoom={false}>
                <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />

            {data.length !== 0 &&
                data.map(({ latitude, longitude }, index) => (
                    latitude && longitude ? (
                        <Marker position={[latitude, longitude]} icon={DefaultIcon} key={index}>
                            <Popup>
                                {index + 1} is for popup with latitude: {latitude} and longitude: {longitude}
                            </Popup>
                        </Marker>
                    ) : null
                ))
            }

            </MapContainer>
        </div>
    </Fragment>
  )
}
