import { Fragment, useEffect, useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import { useDispatch, useSelector } from 'react-redux';

import style from './ForgotPassword.module.css'

import { Button,CircularProgress, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, Paper, TextField } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { sendForgotPassword } from '../../redux/features/Auth/AuthSlice';


export default function ForgotPassword() {


  const { isLoading } = useSelector((state) => state.auth);

  const [validation, setValidation] = useState("")


  const [data, setData] = useState({
    email : ""
  })


  const handleChangeEmail = (email) => {
    setData({...data, email : email.target.value})
  }


  const disptach = useDispatch()
  const navigate = useNavigate()


  const handleClickSubmit = () => {
    if (!data.email) {
      setValidation('Entré votre adresse email svp')
    } else {
      setValidation('')
      disptach(sendForgotPassword(data)).then((value) => {
        navigate('/change-forgot-password/')
      })
    }
  }




  return (
    <Fragment>
      <div className={style.page}>
        <div className={style.formConatiner}>
          <h1>Veuillez saisir votre adresse émail</h1>
          <TextField
              style={{ marginBottom : "30px" }}
              className={style.input} 
              onChange={email => handleChangeEmail(email)}
                label="Adresse émail"
              >
          </TextField>
          

              {validation &&
                  <div className={style.danger}>
                      <p>{validation}</p>
                  </div>
              }
            {isLoading ?
              <CircularProgress />
              :
              <Button
              onClick={handleClickSubmit}
              style={{ background : "#F87575", marginBottom : "47px" }} className={style.button} variant="contained">
                Envoyer
              </Button>
            }
        </div>

      </div>
    </Fragment>
  )
}
