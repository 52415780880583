import React, { useEffect } from 'react'
import { Fragment } from 'react'


import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import PrintIcon from '@mui/icons-material/Print';
import style from './ReportDetails.module.css'
import { getReportDetails } from '../../redux/features/Document/DocumentSlice';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';


import moment from 'moment';

export default function ReportDetails() {


    const navigate = useNavigate()
    const dispatch = useDispatch()
    const params = useParams()

    const patient = params.profilid
    const compterendu = params.compterenduid


    const { createdrep } = useSelector((state) => state.document)

    const userData = {
        compterendu : compterendu,
        patient : patient,
    }

    
    useEffect(() => {
        dispatch(getReportDetails(userData))
    },  [dispatch]);






return (
    <Fragment>
        <div className={style.container}>
            {createdrep !== "" &&
                <div className={style.content}>
                <div className={style.infos}>
                    <div className="doctor">
                    <h2>Dr {createdrep.created_by.nom && createdrep.created_by.nom + " " + createdrep.created_by.prenom }  </h2>
                    <p>{createdrep.created_by.specilite.nom && createdrep.created_by.specilite.nom}</p>
                    <p>{createdrep.created_by.adresse && createdrep.created_by.adresse }, {createdrep.created_by.location.id && createdrep.created_by.location.ville + ' ' + createdrep.created_by.location.code}</p>
                    </div>

                    <div className="patient">
                    <p>{createdrep.date && moment(createdrep.date).format("dddd, Do MMMM YYYY") }</p>
                    {/* <p>{Le 22 juin 2022}</p> */}
                    <h2>{createdrep.patient.nom && createdrep.patient.nom + " "} {createdrep.patient.prenom && createdrep.patient.prenom} </h2>
                    <p>Né le {createdrep.patient.date_de_naissance && createdrep.patient.date_de_naissance}</p>
                    </div>
                </div>
                <div className={style.report}>
                    <div className={style.item}>
                    <h3>Antecedant</h3>
                    <p>{createdrep.antecedant && createdrep.antecedant}</p>
                    </div>
                    <div className={style.item}>
                    <h3>Rapport médecin</h3>
                    <p>{createdrep.text && createdrep.text} </p>
                    </div>
                    <div className={style.item}>
                    <h3>Remarque</h3>
                    <p>{createdrep.remarque && createdrep.remarque}</p>
                    </div>
                </div>
                </div>
            }
        </div>
    </Fragment>
  )
}
