import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";





export const getAllDocument = createAsyncThunk('document/getAllDocument',
  async (patient, thunkAPI) => {
    const {rejectWithValue} = thunkAPI;
    try {
      const res = await fetch(`https://api.doctodz.com/api/report/sharedprescription/${patient}/`, {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            "Authorization": `token ${localStorage.getItem('token')}`,
            "Accept": "application/json",
        },
      });
      const data = await res.json();
      return data
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);


export const getDocumentOfConsultation = createAsyncThunk('document/getDocumentOfConsultation',
  async (userData, thunkAPI) => {
    const { patient, consultation } = userData
    const {rejectWithValue} = thunkAPI;
    try {
      const res = await fetch(`https://api.doctodz.com/api/report/prescriptionofconsultation/${patient}/${consultation}/`, {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            "Authorization": `token ${localStorage.getItem('token')}`,
            "Accept": "application/json",
        },
      });
      const data = await res.json();
      return data
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);


export const getOrdonnanceDetails = createAsyncThunk('document/getOrdonnanceDetails',
  async (userData, thunkAPI) => {
    const { id, patient } = userData
    const {rejectWithValue} = thunkAPI;
    try {
      const res = await fetch(`https://api.doctodz.com/api/prescription/detailsprescriptionpatientview/${id}/${patient}/`, {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            "Authorization": `token ${localStorage.getItem('token')}`,
            "Accept": "application/json",
        },
      });
      const data = await res.json();
      return data
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);




export const getReportDetails = createAsyncThunk('document/getReportDetails',
  async (userData, thunkAPI) => {
    const { compterendu, patient } = userData
    const {rejectWithValue} = thunkAPI;
    try {
      const res = await fetch(`https://api.doctodz.com/api/report/compterendudetailspatient/${compterendu}/${patient}/`, {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            "Authorization": `token ${localStorage.getItem('token')}`,
            "Accept": "application/json",
        },
      });
      const data = await res.json();
      return data
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);


export const shareOrdonnance = createAsyncThunk('document/shareOrdonnance',
  async (formData, thunkAPI) => {
    const {rejectWithValue} = thunkAPI;
    try {
        const res = await fetch("https://api.doctodz.com/api/prescription/sahreprescriptionwithdoctor/", {
          method: 'POST',
          body: JSON.stringify(formData),
          headers: {
            'Content-Type': 'application/json; charset=utf',
            "Authorization": `token ${localStorage.getItem('token')}`,
            "Accept": "application/json",
          },
        });
        const data = await res.json();
        return data
      } catch (error) {
          return rejectWithValue(error.message);
      }
    }
);



export const shareReport = createAsyncThunk('document/shareReport',
  async (formData, thunkAPI) => {
    const {rejectWithValue} = thunkAPI;
    try {
        const res = await fetch("https://api.doctodz.com/api/report/patientsharereport/", {
          method: 'POST',
          body: JSON.stringify(formData),
          headers: {
            'Content-Type': 'application/json; charset=utf',
            "Authorization": `token ${localStorage.getItem('token')}`,
            "Accept": "application/json",
          },
        });
        const data = await res.json();
        return data
      } catch (error) {
          return rejectWithValue(error.message);
      }
    }
);



const DocumentSlice = createSlice({
    name: 'document',
    initialState : {
        alldocument: [],
        docofconsultation: [],
        prescriptiondetails: {},
        sharedordo: {},
        createdrep : "",
        isLoading: false,
        error: null,
    },
    reducers: {},
    extraReducers: {
        [getAllDocument.pending]: (state, action) => {
            state.isLoading = true;
            state.error = null;
        },
        [getAllDocument.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.alldocument = action.payload;
        },
        [getAllDocument.rejected]: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        // getReportDetails
        [getReportDetails.pending]: (state, action) => {
            state.isLoading = true;
            state.error = null;
        },
        [getReportDetails.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.createdrep = action.payload;
        },
        [getReportDetails.rejected]: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        // gggetDocumentOfConsultation
        [getDocumentOfConsultation.pending]: (state, action) => {
            state.isLoading = true;
            state.error = null;
        },
        [getDocumentOfConsultation.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.docofconsultation = action.payload;
        },
        [getDocumentOfConsultation.rejected]: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        [getOrdonnanceDetails.pending]: (state, action) => {
            state.isLoading = true;
            state.error = null;
        },
        [getOrdonnanceDetails.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.prescriptiondetails = action.payload;
        },
        [getOrdonnanceDetails.rejected]: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        [shareOrdonnance.pending]: (state, action) => {
            state.isLoading = true;
            state.error = null;
        },
        [shareOrdonnance.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.sharedordo = action.payload;
        },
        [shareOrdonnance.rejected]: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },
    },
});


// export const { agreeFunc } = authSlice.actions;

export default DocumentSlice.reducer;




