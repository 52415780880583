import React, { useEffect } from 'react'
import { Fragment } from 'react'

import style from './ConsultationDetails.module.css'


import SummarizeIcon from '@mui/icons-material/Summarize';
import ShareIcon from '@mui/icons-material/Share';
import { getDocumentOfConsultation } from '../../redux/features/Document/DocumentSlice';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';



export default function AppoitmentDetails() {


  const { docofconsultation } = useSelector((state) => state.document)


  const dispatch = useDispatch()
  const navigate = useNavigate()
  const params = useParams()


  const patient = params.profilid
  const consultation = params.consultationid


  const userData = {
    "patient" : params.profilid,
    "consultation" : params.consultationid
  }



  useEffect(() => {
    dispatch(getDocumentOfConsultation(userData))
  },  [dispatch]);


  const handleClickDoc = (el) => {
    if(el.type === "Ordonnance") {
      navigate(`/${patient}/appoitment/${consultation}/ordonnance/${el.prescription}/`)
    } else if (el.type === "Compte rendu") {
      navigate(`/${patient}/appoitment/${consultation}/compterendu/${el.compterendu}/`)
    }
  }


  return (
    <Fragment>
      <div className={style.documents}>
        {docofconsultation && docofconsultation.map((el, i) => 
        
          <Fragment key={i} >
              <div onClick={() => handleClickDoc(el)} className={style.document}>
                <div className={style.it}>
                  <SummarizeIcon />
                  <span>{el.type}</span>
                </div>
                <div className={style.it}>
                  <span>{el.date}</span>
                </div>
                <div className={style.it}>
                  <span>Dr {el.doctor}</span>
                </div>
                <div className={style.it} className={style.share}>
                  <ShareIcon />
                  <span>Ouvrir</span>
                </div>
                {/* <div className={style.it} className={style.share}>
                  <ShareIcon />
                  <span>Partager</span>
                </div> */}
              </div>
          </Fragment>
        )}
      </div>
    </Fragment>
  )
}
