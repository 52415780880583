import React, { useEffect } from 'react'
import { Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import SearchBar from '../../Components/SearchBar/SearchBar'


import { getPatientList } from '../../redux/features/Profile/ProfileSlice'


import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';

import style from './ChoiceProfile.module.css'
import QRCode from 'react-qr-code'

export default function ChoiceProfile() {


    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { profilelist } = useSelector((state) => state.profile)

    useEffect(() => {
        dispatch(getPatientList())
},  [dispatch]);


    const handleClickPRofile = (el) => {
        navigate(`/${el}/coordonne/`)
    }


    const handleClickAdd = (el) => {
        navigate('/addprofile/')
    }



  return (
    <Fragment>
      {/* <div className={style.serachbar}>
        <SearchBar />
      </div> */}
      <div className={style.addcon}>
        <div className={style.titel}>
          <h2>Mes profiles</h2>
        </div>
        <div onClick={handleClickAdd} className={style.button}>
          <PersonAddAltIcon />
          <button
          >Ajouter un proche</button>
        </div>
      </div>
      <div className={style.container}>
          {profilelist.length > 0 && profilelist.map((el, i) =>
            <div onClick={() => handleClickPRofile(el.id)} key={i} className={style.profile}>
                <h2>{el.nom} {el.prenom} </h2>
                {/* <p>{el.date_de_naissance}</p> */}
                <div style={{ height: "auto", margin: "0 auto", maxWidth: 64, width: "100%" }}>
                  <QRCode
                    size={256}
                    style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                    value={`https://pro.doctodz.com/dashboard/patients/${el.id}/coordonnees`}
                    viewBox={`0 0 256 256`}
                  />
                </div>
            </div>
          )}
      </div>
    </Fragment>
  )
}
