import React, { useEffect } from 'react'
import { Fragment } from 'react'


import style from './Document.module.css'

import SummarizeIcon from '@mui/icons-material/Summarize';
import ShareIcon from '@mui/icons-material/Share';
import MedicationIcon from '@mui/icons-material/Medication';
import { getAllDocument } from '../../redux/features/Document/DocumentSlice';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import ShareModal from '../../Components/ShareModal/ShareModal';


export default function Document() {


  const { alldocument } = useSelector((state) => state.document)


  const dispatch = useDispatch()
  const navigate = useNavigate()
  const params = useParams()
  const patient = params.profilid



  const [modal, setModal] = useState(false)
  const [formData, setFormData] = useState({
    "prescription" : '',
    "patient": patient,
    "doctor": '',
    "compterendu": '',
    "type": ''
  })


  
  useEffect(() => {
    dispatch(getAllDocument(patient))
  },  [dispatch]);



  const handleClickDoc = (item) => {
    if (item.type === "Ordonance") {
      navigate(`/${patient}/document/ordonnance/${item.prescription}/`)
    } else if (item.type === "Compte rendu") {
      navigate(`/${patient}/document/compterendu/${item.compterendu}/`)
    }
  }


  const handleClickShare = (item) => {
    if (item.type === "Ordonance") {
      setFormData({...formData, prescription : item.prescription, type : item.type })
      setModal(true)
    } 
    else if (item.type === "Compte rendu") {
      setFormData({...formData, compterendu : item.compterendu, type : item.type })
      setModal(true)
    }
  }


  return (
    <Fragment>
      <div className={style.documents}>
        {alldocument && alldocument.map((el, i) => 
        <Fragment key={i}>
          <div className={style.date}>
            <h2>{el.dateList}</h2>
          </div>
          {el.data && el.data.map((item, i) =>
            <div
            key={i} className={style.document}>
              <div onClick={() => handleClickDoc(item)} className={style.it}>
                <MedicationIcon />
                <span>{item.type}</span>
              </div>
              <div className={style.it}>
                <span>{item.date}</span>
              </div>
              <div className={style.it}>
                <span>Dr {item.doctor}</span>
              </div>
              <div onClick={() => handleClickShare(item)} className={style.it} className={style.share}>
                <ShareIcon />
                <span>Partager</span>
              </div>
            </div>
          )}
        </Fragment>
        )}
      </div>
      {modal &&
        <ShareModal formData={formData} setModal={setModal} />
      }
    </Fragment>
  )
}
