import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";



export const SuggesteDoctor = createAsyncThunk('marketing/SuggesteDoctor',
  async (doctor, thunkAPI) => {
    const {rejectWithValue} = thunkAPI;
    try {
        const res = await fetch(`https://api.doctodz.com/api/marketing/doctor/create/`, {
          method: 'POST',
          body: JSON.stringify(doctor),
          headers: {
            'Content-Type': 'application/json; charset=utf',
            "Accept": "application/json",
          },
        });
        const data = await res.json();
        return data
      } catch (error) {
          return rejectWithValue(error.message);
      }
    }
);



const MarketingSlice = createSlice({
    name: 'marketing',
    initialState : {
        suggesteddoctor: null,
        isLoading: false,
        error: null,
    },
    reducers: {},
    extraReducers: {
        // get tarifs
        [SuggesteDoctor.pending]: (state, action) => {
            state.isLoading = true;
            state.error = null;
        },
        [SuggesteDoctor.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.suggesteddoctor = action.payload;
        },
        [SuggesteDoctor.rejected]: (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        },
    },
});


// export const { agreeFunc } = authSlice.actions;

export default MarketingSlice.reducer;